.overlap-group-main {
  height: 100%;
  width: 100%;
  left: 0;
  position: absolute;
  top: 0;
}

.reports-list-container,
.reports-message-text,
.performance-dashboard {
  position: relative;
}

.reports-list-container,
.reports-message-text {
  top: 18vh;
  left: 10vw;
  width: 55vw;
}

.reports-message-text {
  margin-top: 1em;
  font-family: var(--font-family-calibri-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
}

.reports-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.reports-list-item {
  background-color: var(--caramel);
  cursor: pointer;
  height: 3em;
  flex: 1 0 15vw;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-right: 1em;
  text-align: center;
  vertical-align: middle;
  align-items:center;
  justify-content:center;
  font-family: var(--font-family-calibri-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  border-radius: 0.85em;
}

.performance-dashboard {
  background-color: var(--white);
}

.push-down-7 {
  margin-top: 7vh !important;
}
