.fill-in-box-2 {
  border-radius: 0.7em;
  height: 3em;
  position: absolute;
  top: 0;
  left: 0;
  width: 5.75em;
}

.fill-in-box-2.fill-in-box-1 {
  left: 27.5%;
  top: 3em;
}
