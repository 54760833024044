.location-filter {
  align-items: flex-start;
  display: flex;
  width: 1440px;
}

.overlay .location-filter {
  background-color: #00000000;
}

.location-filter-1 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 1024px;
  left: calc(50vw - 720.0px);
  padding: 229px 227px;
  position: relative;
  top: calc(50vh - 512.0px);
  width: 1440px;
}

.overlap-group-7 {
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  height: 31px;
  margin-right: 83px;
  min-width: 120px;
  padding: 5px 11.2px;
}

.vector-6 {
  height: 18px;
  margin-bottom: 1.0px;
  width: 13px;
}

.location-z {
  align-self: flex-end;
  color: var(--teak);
  font-weight: 400;
  margin-left: 11px;
  min-height: 20px;
  min-width: 67px;
}

.filter-options {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  min-height: 162px;
  padding: 13px 15px;
  width: 203px;
}

.flex-row-1 {
  align-items: flex-start;
  display: flex;
  margin-top: 1px;
  min-width: 94px;
}

.vector-5 {
  height: 20px;
  width: 20px;
}

.location {
  color: var(--teak);
  font-weight: 400;
  margin-left: 6px;
  min-height: 20px;
}

.flex-row {
  align-items: flex-start;
  display: flex;
  margin-top: 6px;
  min-width: 94px;
}

.flex-row-2 {
  align-items: flex-start;
  display: flex;
  margin-top: 6px;
  min-width: 93px;
}

.line-18 {
  height: 1px;
  margin-top: 10px;
  width: 146px;
}

.flex-row-3 {
  align-items: center;
  display: flex;
  height: 20px;
  margin-left: 3.25px;
  margin-top: 6px;
  min-width: 102px;
}

.vector-7 {
  cursor: pointer;
  height: 14px;
  width: 14px;
}

.add-location {
  color: var(--teak);
  cursor: pointer;
  font-weight: 400;
  margin-left: 6px;
  min-height: 20px;
  min-width: 82px;
}

#overlay-location-filter {
  background-color: transparent;
}

