.middle-button {
  align-items: flex-start;
  background-color: var(--husk);
  border-radius: 8px;
  display: flex;
  height: 34px;
  margin-left: 1.0px;
  margin-top: 39px;
  min-width: 222px;
  padding: 6px 0;
}

.middle-button-1 {
  letter-spacing: 0;
  min-height: 21px;
  text-align: center;
  width: 222px;
}

.middle-button.middle-button-4 {
  cursor: pointer;
}

