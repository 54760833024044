.box-with-title {
  height: 19%;
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
}

.box-with-title.box-with-title-1 {
  width: 50%;
}
