.multiselect-ddl-container {
  height: 100%;
  width: 100%;
}

.multiselect-ddl-container .MuiFormControl-root {
  width: 100%;
}

.multiselect-ddl-container .MuiFormLabel-root {
  margin-left: -1em;
}

.multiselect-ddl-container .MuiOutlinedInput-notchedOutline {
  border: none;
}

.multiselect-ddl-container .emphasize-first-row {
  border-bottom: 1px solid var(--dark-grey);
}

.multiselect-ddl-container .emphasize-first-row>span.MuiTypography-root {
  font-weight: 700;
}

.multiselect-ddl-container .multiselect-ddl-border {
  margin-left: 0;
  border: none;
  border-bottom: 1px solid var(--dark-grey);
}

.multiselect-ddl-container .multiselect-ddl-border:hover {
  border-bottom: 2px solid black;
}

.multiselect-ddl-container .multiselect-ddl-border>.MuiSelect-select.MuiInputBase-input {
  padding-left: 0;
}

.multiselect-ddl-unavailable-option {
  font-style: italic;
  color: var(--grey);
}
