.elevation-1-radius-10-2,
.elevation-1-radius-10-3 {
  background-color: var(--white);
  border-radius: 10px;
}

.elevation-1-radius-10-2 {
  position: absolute;
  display: flex;
  align-items: flex-start;
  top: 0;
  left: 0;
  height: 15vh;
  min-width: 12vw;
}

.elevation-1-radius-10-3 {
  box-shadow: 0px 4px 8px #0000000d;
  height: 15vh;
  width: 12vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10,
.elevation-1-radius-10-2.elevation-1-radius-10-16,
.elevation-1-radius-10-2.elevation-1-radius-10-17,
.elevation-1-radius-10-2.elevation-1-radius-10-18 {
  height: 17.5vh;
  min-width: 12vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-16 .elevation-1-radius-10-3,
.elevation-1-radius-10-2.elevation-1-radius-10-17 .elevation-1-radius-10-3,
.elevation-1-radius-10-2.elevation-1-radius-10-18 .elevation-1-radius-10-3,
.elevation-1-radius-10-2.elevation-1-radius-10 .elevation-1-radius-10-3 {
  height: 26vh;
  width: 22vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-4 {
  height: 69vh;
  min-width: 44vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-4 .elevation-1-radius-10-3 {
  height: 69vh;
  width: 44vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-6 {
  height: 69vh;
  min-width: 35vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-6 .elevation-1-radius-10-3 {
  height: 69vh;
  width: 35vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-8 {
  height: 29vh;
  min-width: 80vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-8 .elevation-1-radius-10-3 {
  height: 29vh;
  width: 80vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-10 {
  height: 100%;
  width: 100%;
}

.elevation-1-radius-10-2.elevation-1-radius-10-10 .elevation-1-radius-10-3 {
  height: 100%;
  width: 100%;
}

.elevation-1-radius-10-2.elevation-1-radius-10-12 {
  height: 72vh;
  min-width: 37vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-12 .elevation-1-radius-10-3 {
  height: 72vh;
  width: 37vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-14 {
  height: 22vh;
  min-width: 40vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-14 .elevation-1-radius-10-3 {
  height: 22vh;
  width: 40vw;
}

