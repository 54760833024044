.thankyou-popup,
.popup-1,
.enhanve-service-quality-popup {
  align-items: center;
  background-color: var(--white);
  box-shadow: 0px 4px 8px #0000000d;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 40em;
  padding: 4em 0;
  position: absolute;
  top: 0;
  width: 60%;
}

