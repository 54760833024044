.cancel-and-save-changes-1 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: min-content;
  left: 609px;
  position: absolute;
  top: 683px;
  width: min-content;
}

.yes-im-sure {
  cursor: pointer;
  height: 33px;
  margin-right: -2px;
  min-width: 108px;
}

.overlap-group1-3 {
  align-items: flex-end;
  background-color: var(--husk);
  border-radius: 8px;
  display: flex;
  height: 33px;
  justify-content: flex-end;
  min-width: 106px;
  padding: 7px 10px;
}

.save-changes-1 {
  height: 18px;
  letter-spacing: 0;
  min-width: 85px;
  text-align: center;
}

.cancel-and-save-changes-1.cancel-and-save-changes-2 {
  left: 265px;
  top: 694px;
}

.cancel-and-save-changes-1.cancel-and-save-changes-2 .overlap-group1-3,
.cancel-and-save-changes-1.cancel-and-save-changes-3 .overlap-group1-3 {
  padding: 7px 8px;
}

.cancel-and-save-changes-1.cancel-and-save-changes-2 .save-changes-1,
.cancel-and-save-changes-1.cancel-and-save-changes-3 .save-changes-1 {
  min-width: 89px;
}

.cancel-and-save-changes-1.cancel-and-save-changes-3 {
  cursor: pointer;
  left: 313px;
  top: 159px;
}

.cancel-and-save-changes-1.cancel-and-save-changes-3 .yes-im-sure {
  cursor: unset;
}

