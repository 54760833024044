.powerbi-report-container {
  position: absolute;
  border-radius: 0.85em;
  top: 18vh;
  left: 10vw;
  bottom: 1.5vw;
  right: 1vw;
}

.caramel-background {
  background-color: var(--caramel);
}

.full-size {
  width: 100%;
  height: 100%;
}

.align-right {
  text-align: right;
  padding-top: 0.2em;
  padding-right: 1em;
  padding-bottom: 0.2em;
}
