.phone-number {
  align-items: flex-end;
  display: flex;
  height: 46px;
  left: 31px;
  min-width: 266px;
  position: absolute;
  top: 213px;
}

.edit-6 {
  height: 14px;
  margin-bottom: 7.0px;
  width: 14px;
}

