.enhance-service-quality {
  align-items: flex-start;
  display: flex;
  width: 1440px;
}

.overlay .enhance-service-quality {
  background-color: #00000000;
}

.enhance-service-quality-1 {
  align-items: center;
  display: flex;
  height: 1024px;
  left: calc(50vw - 720.0px);
  padding: 0 356px;
  position: relative;
  top: calc(50vh - 512.0px);
  width: 1440px;
}

.overlap-group-27 {
  height: 500px;
  position: relative;
  width: 727px;
}

.qr-code-icon {
  height: 64px;
  left: 332px;
  position: absolute;
  top: 102px;
  width: 64px;
}

#overlay-enhance-service-quality {
  background-color: transparent;
}

