.elevation-1-radius-10-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 374px;
  justify-content: center;
  left: 0;
  min-width: 573px;
  position: absolute;
  top: 0;
}

.elevation-1-radius-10-5 {
  height: 390px;
  margin-top: -4px;
  object-fit: cover;
  width: 589px;
}

.elevation-1-radius-10-1.elevation-1-radius-10-7 {
  background-color: #ffffff4c;
  height: 536px;
  min-width: 1236px;
}

.elevation-1-radius-10-1.elevation-1-radius-10-7 .elevation-1-radius-10-5 {
  height: 552px;
  width: 1252px;
}

