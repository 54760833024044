.my-profile-page {
  background-color: var(--background-color);
  position: relative;
  overflow: hidden;
}

.overlap-group-left,
.overlap-group-right {
  position: absolute;
  top: 25%;
  height: 50%;
}

.overlap-group-left {
    left: 10%
}

.welcome-text-pos {
    top: 10%;
    min-width: 90%;
}

.overlap-group-left {
  width: 30%;
}

.overlap-group-right {
  left: 46%;
  width: 50%;
}

.overlap-group-right>img {
  height: 69vh;
  width: 50vw;
}

.first-last-name,
.e-mail,
.company,
.function {
  position: absolute;
  display: flex;
  align-items: flex-end;
  left: 3em;
  height: 4em;
  min-width: 15em;
}

.first-last-name {
  top: 7em;
}

.e-mail {
  top: 13em;
}

.company {
  top: 19em;
}

.function {
  top: 25em;
}

.roles-list {
  align-items: flex-end;
  display: flex;
  position: absolute;
  left: 3em;
  min-width: 25em;
}

.roles-list {
  top: 8.5em;
  height: 4em;
}

.roles-list ul {
  list-style-type: circle;
}
