html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: PT Serif;
  font-style: italic;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/ptserif/v18/EJRTQgYoZZY2vCFuvAFT_rC1chb-.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: PT Serif;
  font-style: italic;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/ptserif/v18/EJRTQgYoZZY2vCFuvAFT_rm1chb-.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: PT Serif;
  font-style: italic;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/ptserif/v18/EJRTQgYoZZY2vCFuvAFT_rO1chb-.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: PT Serif;
  font-style: italic;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/ptserif/v18/EJRTQgYoZZY2vCFuvAFT_r21cg.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: PT Serif;
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/ptserif/v18/EJRVQgYoZZY2vCFuvAFbzr-tdg.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: PT Serif;
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/ptserif/v18/EJRVQgYoZZY2vCFuvAFSzr-tdg.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: PT Serif;
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/ptserif/v18/EJRVQgYoZZY2vCFuvAFYzr-tdg.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: PT Serif;
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/ptserif/v18/EJRVQgYoZZY2vCFuvAFWzr8.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: PT Serif;
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.gstatic.com/s/ptserif/v18/EJRSQgYoZZY2vCFuvAnt66qfVyvHpA.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: PT Serif;
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.gstatic.com/s/ptserif/v18/EJRSQgYoZZY2vCFuvAnt66qWVyvHpA.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: PT Serif;
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.gstatic.com/s/ptserif/v18/EJRSQgYoZZY2vCFuvAnt66qcVyvHpA.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: PT Serif;
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.gstatic.com/s/ptserif/v18/EJRSQgYoZZY2vCFuvAnt66qSVys.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/merriweathersans/v26/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZou4VhOsH3xE.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/merriweathersans/v26/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZou4VhusH3xE.woff2") format("woff2");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/merriweathersans/v26/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZou4Vh-sH3xE.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/merriweathersans/v26/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZou4ViesH.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Calibri-Regular;
  font-style: normal;
  font-weight: 400;
  src: url("Calibri.fbbbad01.ttf") format("truetype");
}

@font-face {
  font-family: Calibri-Bold;
  font-style: normal;
  font-weight: 700;
  src: url("calibri-bold.cae7dee5.ttf") format("truetype");
}

@font-face {
  font-family: Calibri-Italic;
  font-style: italic;
  font-weight: 400;
  src: url("Calibri Italic.b2aa3ae4.ttf") format("truetype");
}

.screen {
  width: 100vw;
  height: 100vh;
}

.screen a {
  text-decoration: none;
  display: contents;
}

.container-center-horizontal {
  pointer-events: none;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.container-center-horizontal > * {
  pointer-events: auto;
  flex-shrink: 0;
}

.overlay {
  height: 100%;
  opacity: 0;
  width: 100%;
  display: none;
  position: fixed;
  top: 0;
}

.overlay.animate-appear {
  opacity: 0;
  animation: .3s ease-in-out forwards reveal;
  display: block;
}

.overlay.animate-disappear {
  opacity: 1;
  pointer-events: none;
  animation: .3s ease-in-out reverse forwards reveal;
  display: block;
}

.overlay.animate-disappear * {
  pointer-events: none;
}

.align-self-flex-start {
  align-self: flex-start;
}

.align-self-flex-end {
  align-self: flex-end;
}

.align-self-flex-center {
  align-self: flex-center;
}

.valign-text-middle {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

* {
  box-sizing: border-box;
}

:root {
  --background-color: #f7f7f7;
  --caramel: #e1d8c3;
  --grey: #d5d5d5;
  --dark-grey: gray;
  --husk: #b89d5f;
  --primary-text: #000;
  --secondary-text: #888;
  --teak: #af9762;
  --teak-transparent: #af976280;
  --white: #fff;
  --error-background: #faa;
  --error-foreground: #644;
  --confirm-background: #20ff2080;
  --confirm-foreground: black;
  --cancel-background: #ff302080;
  --cancel-foreground: black;
}

:root {
  --font-size-xl: 2.8vh;
  --font-size-l: 2.2vh;
  --font-size-m: 1.8vh;
  --font-size-s: 1.6vh;
  --font-size-xs: 1.3vh;
  --font-size-xxs: 1.1vh;
  --font-size-xxxs: .95vh;
  --font-family-calibri-bold: "Calibri-Bold", Helvetica;
  --font-family-calibri-italic: "Calibri-Italic", Helvetica;
  --font-family-calibri-regular: "Calibri-Regular", Helvetica;
  --font-family-merriweather_sans: "Merriweather Sans", Helvetica;
  --font-family-pt_serif: "PT Serif", Helvetica;
}

.desktopbodycopy {
  font-family: var(--font-family-calibri-regular);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  font-style: normal;
  font-weight: 400;
}

.desktopbodycopy-italic {
  font-family: var(--font-family-calibri-italic);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  font-style: italic;
  font-weight: 400;
}

.desktopbodycopy-s {
  font-family: var(--font-family-calibri-regular);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  font-style: normal;
  font-weight: 400;
}

.desktopfilters {
  font-family: var(--font-family-calibri-regular);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  font-style: normal;
  font-weight: 400;
}

.calibri-bold-black-xs {
  color: var(--primary-text);
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.calibri-normal-black-xs {
  color: var(--primary-text);
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.calibri-bold-black-xxs {
  color: var(--primary-text);
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 700;
}

.calibri-normal-black-xxs {
  color: var(--primary-text);
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.merriweathersans-normal-white-xxxs {
  color: var(--white);
  font-family: var(--font-family-merriweather_sans);
  font-size: var(--font-size-xxxs);
  font-style: normal;
  font-weight: 400;
}

.ptserif-bold-black-xl {
  color: var(--primary-text);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.ptserif-bold-husk-xl {
  color: var(--husk);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.ptserif-bold-black-l {
  color: var(--primary-text);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.ptserif-bold-white-l {
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.ptserif-normal-black-l {
  color: var(--primary-text);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-l);
  font-style: italic;
  font-weight: 400;
}

.ptserif-normal-husk-l {
  color: var(--husk);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-l);
  font-style: italic;
  font-weight: 400;
}

.calibri-bold-white-m {
  color: var(--white);
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.ptserif-bold-white-xs {
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.ptserif-normal-black-xs {
  color: var(--primary-text);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xxs);
  font-style: italic;
  font-weight: 400;
}

.ptserif-bold-white-xxs {
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 700;
}

.ptserif-bold-husk-xxs {
  color: var(--husk);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 700;
}

.border-1px-teak {
  border: 1px solid var(--teak);
}

.border-1px-husk {
  border: 1px solid var(--husk);
}

.home-landing-page {
  background-color: var(--white);
  position: relative;
  overflow: hidden;
}

.overlap-group4 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.left-group {
  background-color: var(--husk);
  min-height: 95vh;
  width: 35vw;
  min-width: 12vw;
  flex-direction: column;
  align-items: center;
  padding: 2em 0;
  display: flex;
  position: absolute;
  top: 4em;
  left: 0;
}

.left-header-1, .left-header-2 {
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  letter-spacing: 0;
  min-height: 1.5em;
  width: 80%;
  text-align: center;
  font-weight: 700;
}

.left-header-1 {
  font-size: var(--font-size-xl);
  margin-top: 1.5vh;
}

.left-header-2 {
  font-size: var(--font-size-m);
  font-style: italic;
}

.ellipse-2 {
  width: 60%;
  object-fit: cover;
  margin-top: 4em;
}

.welcome-message {
  text-align: center;
  color: var(--white);
  font-size: var(--font-size-m);
  width: 80%;
  min-height: 6em;
  margin-top: 2.5em;
  font-weight: 400;
}

.mail-and-mobile {
  height: 3em;
  width: 7vw;
  margin-top: 2.5em;
}

.logo-compass-group_zwart_rgb-1 {
  height: 40px;
  object-fit: cover;
  width: 96px;
}

.name {
  letter-spacing: 0;
  min-height: 40px;
  align-self: flex-start;
  margin-left: 15px;
}

.span1-1 {
  color: var(--primary-text);
  font-weight: 400;
}

.selector-pos {
  height: 25vh;
  min-width: 24vw;
  align-items: flex-start;
  display: flex;
  position: absolute;
}

.selector-pos.dense {
  transform: scaleY(.9);
}

.selector-pos-row-1 {
  top: 32vh;
}

.selector-pos-row-2 {
  top: 66vh;
}

.selector-pos-row-1.dense {
  top: 22vh;
}

.selector-pos-row-2.dense {
  top: 48vh;
}

.selector-pos-row-3.dense {
  top: 74vh;
}

.selector-pos-col-1 {
  left: 39vw;
}

.selector-pos-col-2 {
  left: 64vw;
}

.achtergrond {
  background-color: var(--background-color);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

:root {
  --icon-margin: 1em;
  --icon-size: 30px;
  --header-height: 4em;
}

.header {
  height: var(--header-height);
  width: 100%;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.header-full {
  background-color: var(--caramel);
  height: 4em;
  min-width: 100%;
  align-items: center;
  padding: 12px 20px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.logo-compass-group_zwart_rgb-1-1 {
  cursor: pointer;
  object-fit: cover;
  height: calc(.75 * var(--header-height));
  width: calc(2 * var(--header-height));
}

.vector-logout, .vector-profile, .user-name-function {
  cursor: pointer;
  position: absolute;
}

.vector-logout, .vector-profile {
  height: var(--icon-size);
  width: var(--icon-size);
  margin-left: var(--icon-margin);
  margin-right: var(--icon-margin);
}

.vector-profile {
  left: calc(75% - var(--icon-size));
}

.vector-logout {
  left: calc(75% - 2 * var(--icon-size)  - var(--icon-margin));
}

.user-name-function {
  left: calc(75% + 2 * var(--icon-margin));
  margin-top: .3em;
}

.elevation-1-radius-10-2, .elevation-1-radius-10-3 {
  background-color: var(--white);
  border-radius: 10px;
}

.elevation-1-radius-10-2 {
  height: 15vh;
  min-width: 12vw;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.elevation-1-radius-10-3 {
  height: 15vh;
  width: 12vw;
  box-shadow: 0 4px 8px #0000000d;
}

.elevation-1-radius-10-2.elevation-1-radius-10, .elevation-1-radius-10-2.elevation-1-radius-10-16, .elevation-1-radius-10-2.elevation-1-radius-10-17, .elevation-1-radius-10-2.elevation-1-radius-10-18 {
  height: 17.5vh;
  min-width: 12vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-16 .elevation-1-radius-10-3, .elevation-1-radius-10-2.elevation-1-radius-10-17 .elevation-1-radius-10-3, .elevation-1-radius-10-2.elevation-1-radius-10-18 .elevation-1-radius-10-3, .elevation-1-radius-10-2.elevation-1-radius-10 .elevation-1-radius-10-3 {
  height: 26vh;
  width: 22vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-4 {
  height: 69vh;
  min-width: 44vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-4 .elevation-1-radius-10-3 {
  height: 69vh;
  width: 44vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-6 {
  height: 69vh;
  min-width: 35vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-6 .elevation-1-radius-10-3 {
  height: 69vh;
  width: 35vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-8 {
  height: 29vh;
  min-width: 80vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-8 .elevation-1-radius-10-3 {
  height: 29vh;
  width: 80vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-10, .elevation-1-radius-10-2.elevation-1-radius-10-10 .elevation-1-radius-10-3 {
  height: 100%;
  width: 100%;
}

.elevation-1-radius-10-2.elevation-1-radius-10-12 {
  height: 72vh;
  min-width: 37vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-12 .elevation-1-radius-10-3 {
  height: 72vh;
  width: 37vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-14 {
  height: 22vh;
  min-width: 40vw;
}

.elevation-1-radius-10-2.elevation-1-radius-10-14 .elevation-1-radius-10-3 {
  height: 22vh;
  width: 40vw;
}

.page-selector {
  cursor: pointer;
  align-items: center justify-content: center;
  flex-direction: row;
  padding-left: 1em;
  display: flex;
}

.page-selector-title, .page-selector-subtitle, .page-selector-image {
  text-align: center;
  width: 100%;
  position: relative;
}

.page-selector-title {
  width: 100%;
  letter-spacing: 0;
  top: 7%;
}

.page-selector-subtitle {
  width: 80%;
  color: var(--primary-text);
  padding-left: 20%;
  font-weight: 400;
  position: absolute;
  bottom: 2%;
}

.page-selector-image {
  color: var(--teak);
  top: 44%;
  left: calc(50% - 21.6px);
  transform: scale(1.8);
}

.overlap-group-outer {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  position: relative;
}

.overlap-group-inner {
  height: 100%;
  width: 100%;
  border-radius: 1em;
  position: absolute;
  top: 0;
  left: 0;
}

.page-selector-underline {
  width: 30%;
  height: .2em;
  position: relative;
  top: 10%;
  left: 35%;
}

.type-titles {
  height: 7vh;
  width: 70%;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 10vh;
  left: 32vw;
}

.welcome-header {
  letter-spacing: 0;
  flex: 1;
  margin-top: -1px;
}

.subtitle {
  letter-spacing: 0;
  flex: 1;
}

.type-titles.type-titles-0 {
  width: 65vw;
  padding-left: 4vw;
  left: 35vw;
}

.type-titles.type-titles-1, .type-titles.type-titles-2, .type-titles.type-titles-3, .type-titles.type-titles-4 {
  left: 10vw;
}

.location-filter {
  width: 1440px;
  align-items: flex-start;
  display: flex;
}

.overlay .location-filter {
  background-color: #0000;
}

.location-filter-1 {
  height: 1024px;
  width: 1440px;
  flex-direction: column;
  align-items: flex-end;
  padding: 229px 227px;
  display: flex;
  position: relative;
  top: calc(50vh - 512px);
  left: calc(50vw - 720px);
}

.overlap-group-7 {
  background-color: var(--white);
  height: 31px;
  min-width: 120px;
  border-radius: 5px;
  align-items: center;
  margin-right: 83px;
  padding: 5px 11.2px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.vector-6 {
  height: 18px;
  width: 13px;
  margin-bottom: 1px;
}

.location-z {
  color: var(--teak);
  min-height: 20px;
  min-width: 67px;
  align-self: flex-end;
  margin-left: 11px;
  font-weight: 400;
}

.filter-options {
  background-color: var(--white);
  min-height: 162px;
  width: 203px;
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 7px;
  padding: 13px 15px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.flex-row-1 {
  min-width: 94px;
  align-items: flex-start;
  margin-top: 1px;
  display: flex;
}

.vector-5 {
  height: 20px;
  width: 20px;
}

.location {
  color: var(--teak);
  min-height: 20px;
  margin-left: 6px;
  font-weight: 400;
}

.flex-row {
  min-width: 94px;
  align-items: flex-start;
  margin-top: 6px;
  display: flex;
}

.flex-row-2 {
  min-width: 93px;
  align-items: flex-start;
  margin-top: 6px;
  display: flex;
}

.line-18 {
  height: 1px;
  width: 146px;
  margin-top: 10px;
}

.flex-row-3 {
  height: 20px;
  min-width: 102px;
  align-items: center;
  margin-top: 6px;
  margin-left: 3.25px;
  display: flex;
}

.vector-7 {
  cursor: pointer;
  height: 14px;
  width: 14px;
}

.add-location {
  color: var(--teak);
  cursor: pointer;
  min-height: 20px;
  min-width: 82px;
  margin-left: 6px;
  font-weight: 400;
}

#overlay-location-filter {
  background-color: #0000;
}

.my-profile-page {
  background-color: var(--background-color);
  position: relative;
  overflow: hidden;
}

.overlap-group-left, .overlap-group-right {
  height: 50%;
  position: absolute;
  top: 25%;
}

.overlap-group-left {
  left: 10%;
}

.welcome-text-pos {
  min-width: 90%;
  top: 10%;
}

.overlap-group-left {
  width: 30%;
}

.overlap-group-right {
  width: 50%;
  left: 46%;
}

.overlap-group-right > img {
  height: 69vh;
  width: 50vw;
}

.first-last-name, .e-mail, .company, .function {
  height: 4em;
  min-width: 15em;
  align-items: flex-end;
  display: flex;
  position: absolute;
  left: 3em;
}

.first-last-name {
  top: 7em;
}

.e-mail {
  top: 13em;
}

.company {
  top: 19em;
}

.function {
  top: 25em;
}

.roles-list {
  min-width: 25em;
  height: 4em;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: 8.5em;
  left: 3em;
}

.roles-list ul {
  list-style-type: circle;
}

.inner-subjects {
  height: min-content;
  width: 544px;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  display: flex;
  position: absolute;
  top: 15px;
  left: 39px;
}

.desktop-h4-header-2 {
  height: 31px;
  letter-spacing: 0;
  width: 485px;
  margin-top: -1px;
}

.line-2 {
  height: 2px;
  min-width: 64px;
  margin-bottom: -1px;
}

.inner-subjects.inner-subjects-1 {
  width: 434px;
  left: 31px;
}

.inner-subjects.inner-subjects-1 .desktop-h4-header-2 {
  margin-right: -51px;
}

.inner-subjects.inner-subjects-2 {
  width: min-content;
  top: 17px;
  left: 25px;
}

.inner-subjects.inner-subjects-3 {
  width: min-content;
  top: 16px;
  left: 26px;
}

.inner-subjects.inner-subjects-4 {
  width: min-content;
  top: 16px;
  left: 25px;
}

.inner-subjects.inner-subjects-5 {
  width: min-content;
  top: 16px;
  left: 20px;
}

.inner-subjects.inner-subjects-6 {
  width: min-content;
  top: 18px;
  left: 24px;
}

.box-with-title {
  height: 19%;
  width: 40%;
  position: absolute;
  top: 0;
  left: 0;
}

.box-with-title.box-with-title-1 {
  width: 50%;
}

.inner-content-1 {
  height: min-content;
  width: 12\65 ;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.desktop-bodycopy-18pt-calibri-regular {
  color: var(--secondary-text);
  margin-top: -1px;
  font-weight: 400;
}

.desktop-bodycopy-20pt-calibri-regular {
  color: var(--primary-text);
  font-weight: 400;
}

.inner-content-1.inner-content-2 {
  width: 30em;
  position: absolute;
  top: 0;
  left: 0;
}

.inner-content-1.inner-content-3 {
  width: min-content;
}

.inner-content-1.inner-content {
  width: min-content;
  margin-left: 7em;
}

.inner-content-1.inner-content-4 {
  width: 24em;
}

.inner-content-1.inner-content-7 {
  width: 13em;
}

.inner-content-1.inner-content-5, .inner-content-1.inner-content-6, .inner-content-1.inner-content-8, .inner-content-1.inner-content-9 {
  width: 100%;
}

.phone-number {
  height: 46px;
  min-width: 266px;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: 213px;
  left: 31px;
}

.edit-6 {
  height: 14px;
  width: 14px;
  margin-bottom: 7px;
}

:root {
  --left-navigation-bar-width: 4em;
  --left-navigation-bar-height: calc(100vh - 4em);
  --left-navigation-bar-y: 4em;
}

.left-navigation-bar-container {
  top: var(--left-navigation-bar-y);
  height: var(--left-navigation-bar-height);
  width: var(--left-navigation-bar-width);
  position: absolute;
  left: 0;
}

.left-navigationbar {
  background-color: var(--husk);
  min-height: var(--left-navigation-bar-height);
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 1em 0;
  display: flex;
  top: 0;
  left: 0;
}

.left-navigationbar .left-navigationbar-icon {
  cursor: pointer;
}

.left-navigationbar-icon {
  color: var(--white);
  height: 5em;
  width: 5em;
  margin-left: .3em;
  margin-right: .3em;
  transform: scale(1.15);
}

.left-navigationbar .position-top {
  margin-top: .25em;
  margin-bottom: .5em;
  position: relative;
}

.left-navigationbar img.position-bottom {
  position: absolute;
}

img.position-bottom:nth-last-child(1) {
  --nth-last-child: 1;
}

img.position-bottom:nth-last-child(2) {
  --nth-last-child: 2;
}

img.position-bottom:nth-last-child(3) {
  --nth-last-child: 3;
}

.left-navigationbar img.position-bottom:nth-last-child(-n+3) {
  bottom: calc(10px + var(--nth-last-child) * var(--left-navigation-bar-width));
}

.transform-svg-colors {
  -webkit-filter: invert(25%);
  filter: invert(25%);
}

.left-navigationbar-icon {
  width: 32px;
}

:root {
  --image-width: 33px;
  --image-height: 37px;
  --date-width: 8em;
  --document-name-width: 50%;
}

.document-page {
  background-color: var(--white);
  position: relative;
  overflow: hidden;
}

.contracts {
  height: 25em;
  min-width: 30vw;
  position: absolute;
  top: 25vh;
  left: 10vw;
}

.overlap-group10 {
  height: 100%;
  width: 100%;
  min-width: 30vw;
  position: relative;
  top: 0;
  left: 0;
}

.arrow-forward-header {
  height: 1.5em;
  width: .6em;
  align-self: center;
  margin-top: .2em;
  margin-left: 1em;
  margin-right: 1em;
}

.document-status-icon {
  float: right;
  height: 60%;
  margin-right: 15%;
}

.document-status-icon img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.document-icon {
  height: var(--image-height);
  width: var(--image-width);
  object-fit: cover;
  align-self: center;
  margin: .2em;
}

.document-filename, .document-date, .document-size {
  position: absolute;
}

.document-date, .document-size {
  color: var(--husk);
  font-weight: 400;
}

.document-filename, .document-size {
  left: calc(var(--image-width)  + .5em);
  font-weight: 400;
}

.document-filename {
  min-width: 50%;
  color: var(--primary-text);
  font-weight: 400;
  position: absolute;
  top: 0;
}

.document-date {
  top: .2em;
  left: calc(var(--image-width)  + var(--document-name-width));
  width: var(--date-width);
}

.document-size {
  padding-left: .4em;
  top: 1.8em;
}

.flex-row {
  height: 4em;
  min-width: 25em;
  align-items: flex-start;
  display: flex;
  position: absolute;
}

.flex-row-inner {
  height: 3.5em;
  width: 100%;
  margin-left: 1em;
  position: relative;
  left: 3em;
}

.row-1 {
  top: 4em;
}

.row-2 {
  top: 9em;
}

.row-3 {
  top: 14em;
}

.row-4 {
  top: 19em;
}

.row-5 {
  top: 24em;
}

.width-50 {
  width: 100%;
  min-width: 50vw;
}

.view-all-2 {
  color: var(--husk);
  font-weight: 400;
}

.view-all-3 {
  height: 3em;
  width: 100%;
  min-width: 7em;
  align-items: flex-end;
  margin-right: 10%;
  display: flex;
  position: relative;
  top: 0;
  right: -80%;
}

.arrow-forward-6 {
  height: 1em;
  width: .85em;
  align-self: center;
  margin-top: 1.3em;
  margin-bottom: .25em;
  margin-left: .85em;
}

.elevation-1-radius-10-1 {
  background-color: var(--white);
  height: 374px;
  min-width: 573px;
  border-radius: 10px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.elevation-1-radius-10-5 {
  height: 390px;
  object-fit: cover;
  width: 589px;
  margin-top: -4px;
}

.elevation-1-radius-10-1.elevation-1-radius-10-7 {
  height: 536px;
  min-width: 1236px;
  background-color: #ffffff4c;
}

.elevation-1-radius-10-1.elevation-1-radius-10-7 .elevation-1-radius-10-5 {
  height: 552px;
  width: 1252px;
}

.clients-overview-page {
  background-color: var(--white);
  position: relative;
  overflow: hidden;
}

.overlap-group-clients-overview {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.organizations-table-container {
  width: 80vw;
  min-height: 8vh;
  max-height: 65vh;
  position: absolute;
  top: 18vh;
  left: 10vw;
}

.organizations-table, .organizations-table-placeholder {
  margin-bottom: 2em;
  position: relative;
}

.organizations-table-placeholder {
  height: 10vh;
  top: 10vh;
}

.registration-link-modal, .message-modal {
  font-family: var(--font-family-calibri-regular);
  color: var(--error-foreground);
  background-color: var(--caramel);
  text-align: left;
  border-style: solid;
  border-color: var(--dask);
  overflow-wrap: break-word;
  border-width: .2em;
  padding: 1em;
  font-weight: 400;
  position: absolute;
}

.registration-link-modal:hover, .message-modal:hover {
  cursor: pointer;
}

.registration-link-modal {
  width: 60vw;
  z-index: 1;
  font-size: var(--font-size-xs);
  top: 40vh;
  left: 20vw;
}

.registration-link-modal a.subject-header {
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-s);
  font-weight: 700;
}

.registration-link-modal .registration-link-item {
  margin-top: .25em;
  margin-bottom: .5em;
}

.registration-link-modal .registration-link-modal-footer {
  text-align: right;
}

.message-modal {
  width: 20vw;
  z-index: 2;
  font-size: var(--font-size-m);
  top: 45vh;
  left: 40vw;
}

.editable-table {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

div.MuiToolbar-root {
  background-color: var(--caramel);
}

div.MuiToolbar-root .MuiButton-root {
  background-color: var(--husk);
  margin-top: .1em;
  margin-bottom: .2em;
  margin-left: 1em;
}

div.MuiToolbar-root .MuiBox-root {
  margin-top: .1em;
}

button.MuiIconButton-root {
  color: var(--husk);
}

div.MuiDialogActions-root button.MuiButton-textPrimary, div.MuiDialogActions-root button.MuiButton-textSecondary {
  color: var(--husk);
  font-weight: 700;
}

div.MuiDialogActions-root button.MuiButton-containedPrimary, div.MuiDialogActions-root button.MuiButton-containedSecondary {
  background-color: var(--husk);
  color: var(--white);
  font-weight: 700;
}

.dialog-title {
  color: var(--husk);
}

tr td:nth-child(1) button {
  margin-left: -.2em;
}

tr td:nth-child(1) button:nth-child(1) {
  margin-left: .1em;
}

th.MuiTableCell-root.MuiTableCell-head:nth-child(1) div.Mui-TableHeadCell-Content {
  left: -1em;
}

.multiselect-ddl-container {
  height: 100%;
  width: 100%;
}

.multiselect-ddl-container .MuiFormControl-root {
  width: 100%;
}

.multiselect-ddl-container .MuiFormLabel-root {
  margin-left: -1em;
}

.multiselect-ddl-container .MuiOutlinedInput-notchedOutline {
  border: none;
}

.multiselect-ddl-container .emphasize-first-row {
  border-bottom: 1px solid var(--dark-grey);
}

.multiselect-ddl-container .emphasize-first-row > span.MuiTypography-root {
  font-weight: 700;
}

.multiselect-ddl-container .multiselect-ddl-border {
  border: none;
  border-bottom: 1px solid var(--dark-grey);
  margin-left: 0;
}

.multiselect-ddl-container .multiselect-ddl-border:hover {
  border-bottom: 2px solid #000;
}

.multiselect-ddl-container .multiselect-ddl-border > .MuiSelect-select.MuiInputBase-input {
  padding-left: 0;
}

.multiselect-ddl-unavailable-option {
  color: var(--grey);
  font-style: italic;
}

.reports-overview-page {
  background-color: var(--white);
  position: relative;
  overflow: hidden;
}

.overlap-group-reports-overview {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.reports-table-container {
  width: 80vw;
  min-height: 8vh;
  max-height: 65vh;
  position: absolute;
  top: 18vh;
  left: 10vw;
}

.reports-table, .reports-table-placeholder {
  margin-bottom: 2em;
  position: relative;
}

.reports-table-placeholder {
  height: 8vh;
}

.reports-message-modal {
  width: 40vw;
  z-index: 2;
  font-family: var(--font-family-calibri-regular);
  font-size: var(--font-size-m);
  color: var(--error-foreground);
  background-color: var(--caramel);
  text-align: left;
  border-style: solid;
  border-color: var(--dask);
  overflow-wrap: break-word;
  border-width: .2em;
  padding: 1em;
  font-weight: 400;
  position: absolute;
  top: 45vh;
  left: 30vw;
}

.reports-message-modal:hover {
  cursor: pointer;
}

.users-overview-page {
  background-color: var(--white);
  position: relative;
  overflow: hidden;
}

.overlap-group-users-overview {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.users-table-container {
  width: 80vw;
  min-height: 8vh;
  max-height: 65vh;
  position: absolute;
  top: 18vh;
  left: 10vw;
}

.users-table, .users-table-placeholder {
  margin-bottom: 2em;
  position: relative;
}

.users-table td.MuiTableCell-root > div.nested-table-cell {
  border: none;
  padding: 0;
}

.users-table-placeholder {
  height: 10vh;
  top: 10vh;
}

.reload-users-button {
  minimum-width: 10em;
  color: #000;
  background-color: var(--caramel);
}

.registration-link-modal, .message-modal {
  font-family: var(--font-family-calibri-regular);
  color: var(--error-foreground);
  background-color: var(--caramel);
  text-align: left;
  border-style: solid;
  border-color: var(--dask);
  overflow-wrap: break-word;
  border-width: .2em;
  padding: 1em;
  font-weight: 400;
  position: absolute;
}

.registration-link-modal:hover, .message-modal:hover {
  cursor: pointer;
}

.registration-link-modal {
  width: 60vw;
  z-index: 1;
  font-size: var(--font-size-xs);
  top: 40vh;
  left: 20vw;
}

.registration-link-modal a.subject-header {
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-s);
  font-weight: 700;
}

.registration-link-modal .registration-link-item {
  margin-top: .25em;
  margin-bottom: .5em;
}

.registration-link-modal .registration-link-modal-footer {
  text-align: right;
}

.message-modal {
  width: 20vw;
  z-index: 2;
  font-size: var(--font-size-m);
  top: 45vh;
  left: 40vw;
}

:root {
  --text-field-height: 1.6em;
}

.registration-screen {
  background-color: var(--white);
  align-items: flex-start;
  display: flex;
}

.proposition-summary {
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
}

.overlap-group-registration-left, .overlap-group-registration-right {
  height: 100vh;
  flex-direction: column;
  align-items: center;
  padding-left: 6em;
  padding-right: 6em;
  display: flex;
  position: relative;
  top: 0;
}

.overlap-group-registration-left {
  background-image: url("rectangle-7@1x.dd23098e.svg");
}

.overlap-group-registration-right {
  background-color: var(--background-color);
}

.login-and-discover-the-portal {
  height: 2em;
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
  margin-top: 2em;
  font-weight: 700;
}

.login-area {
  min-width: 30vw;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.register {
  letter-spacing: 0;
  margin-top: 2em;
  margin-bottom: 2em;
  left: -8em;
}

.registration-form-head {
  margin-top: 2em;
  margin-bottom: 1em;
}

.form-item {
  background-color: var(--grey);
  height: 4em;
  margin-left: .1em;
  margin-top: calc((var(--text-field-height)) / 2);
  min-width: 35vw;
  padding: calc((var(--text-field-height)) / 4);
  background-color: var(--caramel);
  border-radius: .7em;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.form-item-readonly {
  height: 3.5em;
  padding-top: calc((var(--text-field-height)) * .5);
}

.form-item input {
  color: var(--black);
  width: 80%;
  background-color: var(--background-color);
  border: 0;
  align-self: center;
  margin-left: .2em;
  font-weight: 400;
  position: relative;
}

.form-item input:disabled {
  background-color: initial;
  margin-left: .5em;
}

.custom-icon {
  color: #fff;
  margin-top: calc((var(--text-field-height)) * .33);
  margin-left: 3em;
  margin-right: 1em;
}

.form-item-readonly .custom-icon {
  margin-top: calc((var(--text-field-height)) * .1);
  margin-bottom: calc((var(--text-field-height)) * .1);
}

.span1-5 {
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  font-weight: 700;
}

.logo-compass-group_zwart_goud_rgb-1 {
  width: 30%;
  z-index: 1;
  position: absolute;
  bottom: 10%;
  left: 35%;
}

.modal {
  width: 40vw;
  height: auto;
  z-index: 1;
  text-align: left;
  overflow-wrap: break-word;
  font-family: var(--font-family-calibri-regular);
  font-size: var(--font-size-m);
  border-style: solid;
  border-width: .2em;
  padding: 1em;
  font-weight: 400;
  position: absolute;
  top: 40vh;
  left: 30vw;
}

.modal.token-validation-error {
  height: auto;
  font-family: var(--font-family-calibri-bold);
  padding: 1em;
  font-weight: 700;
}

.modal.message-modal {
  text-align: center;
  color: var(--husk);
  background-color: var(--background-color);
  border-color: var(--teak);
}

.modal.error-modal {
  color: var(--error-foreground);
  background-color: var(--error-background);
  border-color: var(--error-foreground);
}

.intro-text {
  text-align: center;
  color: var(--white);
  font-size: var(--font-size-m);
  width: 35vw;
}

.login-button {
  background-color: var(--teak);
  cursor: pointer;
  height: 3.5em;
  min-width: 25vw;
  border-radius: .7em;
  align-items: center;
  margin-top: 4em;
  display: flex;
}

.big-button {
  left: calc(50% - 5vw);
  width: 10vw;
  text-align: center;
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  font-weight: 700;
  position: relative;
}

.login-button-disabled {
  background-color: var(--teak-transparent);
}

.welcome-to-compass-group {
  text-align: center;
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
  min-height: 4em;
  margin-top: 2em;
  font-weight: 700;
}

:root {
  --text-field-height: 1.6em;
}

.password-reset-screen {
  background-color: var(--white);
  align-items: flex-start;
  display: flex;
}

.proposition-summary {
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
}

.overlap-group-password-reset-left, .overlap-group-password-reset-right {
  height: 100vh;
  flex-direction: column;
  align-items: center;
  padding-left: 6em;
  padding-right: 6em;
  display: flex;
  position: relative;
  top: 0;
}

.overlap-group-password-reset-left {
  background-image: url("rectangle-7@1x.dd23098e.svg");
}

.overlap-group-password-reset-right {
  background-color: var(--background-color);
}

.login-and-discover-the-portal {
  height: 2em;
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
  margin-top: 2em;
  font-weight: 700;
}

.password-reset-area {
  min-width: 30vw;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.reset-password-message {
  letter-spacing: 0;
  margin-top: 2em;
  margin-bottom: 2em;
  left: -8em;
}

.password-reset-form-head {
  margin-top: 2em;
  margin-bottom: 1em;
}

.form-item {
  background-color: var(--grey);
  height: 4em;
  margin-left: .1em;
  margin-top: calc((var(--text-field-height)) / 2);
  min-width: 35vw;
  padding: calc((var(--text-field-height)) / 4);
  background-color: var(--caramel);
  border-radius: .7em;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.form-item-readonly {
  height: 3.5em;
  padding-top: calc((var(--text-field-height)) * .5);
}

.form-item input {
  color: var(--black);
  width: 80%;
  background-color: var(--background-color);
  border: 0;
  align-self: center;
  margin-left: .2em;
  font-weight: 400;
  position: relative;
}

.form-item input:disabled {
  background-color: initial;
  margin-left: .5em;
}

.custom-icon {
  color: #fff;
  margin-top: calc((var(--text-field-height)) * .33);
  margin-left: 3em;
  margin-right: 1em;
}

.form-item-readonly .custom-icon {
  margin-top: calc((var(--text-field-height)) * .1);
  margin-bottom: calc((var(--text-field-height)) * .1);
}

.span1-5 {
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  font-weight: 700;
}

.logo-compass-group_zwart_goud_rgb-1 {
  width: 30%;
  z-index: 1;
  position: absolute;
  bottom: 10%;
  left: 35%;
}

.modal {
  width: 40vw;
  height: auto;
  z-index: 1;
  text-align: left;
  overflow-wrap: break-word;
  font-family: var(--font-family-calibri-regular);
  font-size: var(--font-size-m);
  border-style: solid;
  border-width: .2em;
  padding: 1em;
  font-weight: 400;
  position: absolute;
  top: 40vh;
  left: 30vw;
}

.modal.token-validation-error {
  height: auto;
  font-family: var(--font-family-calibri-bold);
  padding: 1em;
  font-weight: 700;
}

.modal.message-modal {
  text-align: center;
  color: var(--husk);
  background-color: var(--background-color);
  border-color: var(--teak);
}

.modal.error-modal {
  color: var(--error-foreground);
  background-color: var(--error-background);
  border-color: var(--error-foreground);
}

.last-year-filter {
  width: 1440px;
  align-items: flex-start;
  display: flex;
}

.overlay .last-year-filter {
  background-color: #0000;
}

.last-year-filter-1 {
  height: 1024px;
  width: 1440px;
  flex-direction: column;
  align-items: flex-end;
  padding: 229px 101px;
  display: flex;
  position: relative;
  top: calc(50vh - 512px);
  left: calc(50vw - 720px);
}

.overlap-group-15 {
  background-color: var(--white);
  height: 31px;
  min-width: 139px;
  border-radius: 5px;
  align-items: center;
  margin-right: 64px;
  padding: 5px 13.2px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.vector-24 {
  height: 18px;
  width: 16px;
  margin-bottom: 1.11px;
}

.last-year {
  color: var(--teak);
  min-height: 20px;
  min-width: 58px;
  align-self: flex-end;
  margin-left: 9px;
  font-weight: 400;
}

.filter-options-1 {
  background-color: var(--white);
  min-height: 183px;
  width: 203px;
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 7px;
  padding: 14px 15px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.flex-row-4 {
  min-width: 79px;
  align-items: flex-start;
  display: flex;
}

.vector-23 {
  height: 20px;
  width: 20px;
}

.last {
  color: var(--teak);
  min-height: 20px;
  margin-left: 6px;
  font-weight: 400;
}

.flex-row-5 {
  height: 21px;
  min-width: 105px;
  align-items: flex-start;
  margin-top: 6px;
  display: flex;
}

.vector-25 {
  height: 20px;
  width: 20px;
  align-self: flex-end;
}

.flex-row-6 {
  min-width: 105px;
  align-items: flex-start;
  margin-top: 5px;
  display: flex;
}

.flex-row-7 {
  height: 21px;
  min-width: 105px;
  align-items: flex-start;
  margin-top: 5px;
  display: flex;
}

.last-quarter {
  color: var(--teak);
  min-height: 20px;
  min-width: 79px;
  align-self: flex-end;
  margin-left: 6px;
  font-weight: 400;
}

.flex-row-8 {
  min-width: 84px;
  align-items: flex-start;
  margin-top: 4px;
  display: flex;
}

.line-18-1 {
  height: 1px;
  width: 146px;
  margin-top: 8px;
}

.flex-row-9 {
  height: 20px;
  min-width: 102px;
  align-items: center;
  margin-top: 4px;
  margin-left: 3.25px;
  display: flex;
}

.vector-26 {
  cursor: pointer;
  height: 14px;
  width: 14px;
}

.custom-date {
  color: var(--teak);
  cursor: pointer;
  min-height: 20px;
  min-width: 83px;
  margin-left: 6px;
  font-weight: 400;
}

#overlay-last-year-filter {
  background-color: #0000;
}

.years-filter-documents-sla {
  width: 1440px;
  align-items: flex-start;
  display: flex;
}

.overlay .years-filter-documents-sla {
  background-color: #0000;
}

.years-filter-documents-sla-1 {
  height: 1024px;
  width: 1440px;
  flex-direction: column;
  align-items: flex-end;
  padding: 229px 3px;
  display: flex;
  position: relative;
  top: calc(50vh - 512px);
  left: calc(50vw - 720px);
}

.x2022-1 {
  cursor: pointer;
  min-width: 90px;
  align-items: flex-start;
  margin-right: 113px;
  display: flex;
}

.overlap-group1-5 {
  background-color: var(--white);
  height: 31px;
  min-width: 88px;
  border-radius: 5px;
  align-items: flex-start;
  padding: 5px 11.2px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.vector-28 {
  height: 18px;
  width: 16px;
  align-self: center;
  margin-bottom: 1.11px;
}

.number-4 {
  color: var(--teak);
  min-height: 20px;
  margin-left: 9px;
  font-weight: 400;
}

.overlap-group-16 {
  background-color: var(--white);
  min-height: 183px;
  width: 203px;
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 7px;
  padding: 13px 15px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.flex-row-10 {
  height: 21px;
  min-width: 65px;
  align-items: flex-start;
  margin-top: 1px;
  display: flex;
}

.vector-27 {
  height: 20px;
  width: 20px;
}

.number-3 {
  color: var(--teak);
  min-height: 20px;
  min-width: 33px;
  align-self: flex-end;
  margin-left: 12px;
  font-weight: 400;
}

.flex-row-11 {
  height: 21px;
  min-width: 65px;
  align-items: flex-start;
  margin-top: 5px;
  display: flex;
}

.flex-row-12 {
  height: 21px;
  min-width: 64px;
  align-items: flex-start;
  margin-top: 5px;
  display: flex;
}

.number-5 {
  color: var(--teak);
  min-height: 20px;
  min-width: 33px;
  align-self: flex-end;
  margin-left: 11px;
  font-weight: 400;
}

.flex-row-13 {
  height: 22px;
  min-width: 65px;
  align-items: flex-start;
  margin-top: 4px;
  display: flex;
}

.flex-row-14 {
  height: 21px;
  min-width: 65px;
  align-items: flex-start;
  margin-top: 3px;
  display: flex;
}

.line-18-2 {
  height: 1px;
  width: 146px;
  margin-top: 6px;
}

.flex-row-15 {
  height: 20px;
  min-width: 112px;
  align-items: center;
  margin-top: 6px;
  margin-left: 3.25px;
  display: flex;
}

.vector-29 {
  cursor: pointer;
  height: 14px;
  width: 14px;
  margin-bottom: 2px;
}

.custom-date-1 {
  color: var(--teak);
  cursor: pointer;
  min-height: 20px;
  min-width: 83px;
  margin-left: 15px;
  font-weight: 400;
}

#overlay-years-filter-documents-sla {
  background-color: #0000;
}

.frame-4 {
  height: 15%;
  width: 37%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.text {
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  align-self: stretch;
  margin-top: -1px;
}

.explanation {
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  align-self: stretch;
}

.frame-4.frame-4-1, .frame-4.frame-4-2, .frame-4.frame-4-3 {
  margin-top: 15em;
}

.middle-button {
  background-color: var(--husk);
  height: 34px;
  min-width: 222px;
  border-radius: 8px;
  align-items: flex-start;
  margin-top: 39px;
  margin-left: 1px;
  padding: 6px 0;
  display: flex;
}

.middle-button-1 {
  letter-spacing: 0;
  min-height: 21px;
  text-align: center;
  width: 222px;
}

.middle-button.middle-button-4 {
  cursor: pointer;
}

.thankyou-popup, .popup-1, .enhanve-service-quality-popup {
  background-color: var(--white);
  min-height: 40em;
  width: 60%;
  flex-direction: column;
  align-items: center;
  padding: 4em 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 4px 8px #0000000d;
}

.thank-you {
  width: 1440px;
  align-items: flex-start;
  display: flex;
}

.overlay .thank-you {
  background-color: #0000;
}

.thank-you-1 {
  height: 1024px;
  width: 1440px;
  align-items: center;
  padding: 0 356px;
  display: flex;
  position: relative;
  top: calc(50vh - 512px);
  left: calc(50vw - 720px);
}

.overlap-group-17 {
  height: 500px;
  width: 727px;
  position: relative;
}

.mail {
  height: 61px;
  width: 77px;
  position: absolute;
  top: 103px;
  left: 325px;
}

#overlay-thank-you {
  background-color: #0000;
}

:root {
  --text-field-height: 1.6em;
}

.login-screen {
  background-color: var(--white);
  align-items: flex-start;
  display: flex;
}

.proposition-summary {
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
}

.overlap-group-login-left, .overlap-group-login-right {
  width: 50vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  padding-left: 4em;
  padding-right: 4em;
  display: flex;
  position: relative;
  top: 0;
}

.overlap-group-login-left {
  background-image: url("rectangle-7@1x.dd23098e.svg");
}

.overlap-group-login-right {
  background-color: var(--background-color);
}

.login-and-discover-the-portal {
  height: 2em;
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
  margin-top: 2em;
  font-weight: 700;
}

.login-area {
  min-width: 30vw;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.login {
  letter-spacing: 0;
  margin-top: 2em;
  margin-bottom: 2em;
  left: -8em;
}

.please-enter-your-username-and-password {
  margin-top: 2em;
  margin-bottom: 1em;
}

.username, .password {
  background-color: var(--grey);
  height: 4.5em;
  min-width: 35vw;
  padding: calc((var(--text-field-height)) / 4);
  border-radius: .7em;
  justify-content: center;
  align-items: flex-start;
  margin-left: .1em;
  display: flex;
  position: relative;
}

.password {
  margin-top: calc((var(--text-field-height)) / 2);
  padding-top: calc((var(--text-field-height)) / 2);
}

.username-failed, .password-failed, .username-failed input, .password-failed input {
  color: var(--teak);
}

.username-input, .password-input {
  color: var(--black);
  background-color: var(--grey);
  align-self: center;
  font-weight: 400;
}

input.username-input, input.password-input {
  width: 70%;
  border: 0;
  margin-left: 0;
  position: relative;
}

.profile-filled-2, .vector-30 {
  height: calc((var(--text-field-height)) * 1.2);
  width: calc((var(--text-field-height)) * 1.2);
  align-self: center;
  margin-left: 20%;
  position: relative;
}

.vector-30 {
  margin-top: -2%;
}

.forgot-password {
  min-height: 2em;
  min-width: 10vw;
  width: 100%;
  color: var(--primary-text);
  text-align: right;
  margin-top: 0;
  margin-left: 0;
  padding-right: 10em;
  font-style: italic;
  font-weight: 400;
}

.no-account-yet-register {
  color: var(--primary-text);
  min-height: 2em;
  min-width: 18em;
  margin-top: .25em;
}

.span0-4 {
  font-weight: 400;
}

.span1-5 {
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  font-weight: 700;
}

.logo-compass-group-login-screen {
  width: 40%;
  z-index: 1;
  margin-top: 2em;
  margin-bottom: auto;
}

.client-dashboard-modal {
  font-family: var(--font-family-calibri-regular);
  color: var(--error-foreground);
  background-color: var(--caramel);
  text-align: left;
  border-style: solid;
  border-color: var(--dask);
  overflow-wrap: break-word;
  border-width: .2em;
  padding: 1em;
  font-weight: 400;
  position: absolute;
  left: 10vw;
}

.client-dashboard-modal:hover {
  cursor: pointer;
}

.password-change-confirmation-modal {
  background-color: var(--caramel);
  width: 60vw;
  z-index: 1;
  font-size: var(--font-size-xs);
  top: 40vh;
  left: 20vw;
}

.password-change-confirmation-modal a.subject-header {
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-s);
  font-weight: 700;
}

.password-change-confirmation-modal .modal-content-item {
  margin-top: .25em;
  margin-bottom: .5em;
}

.password-change-confirmation-modal .registration-link-modal-footer {
  width: 100%;
  text-align: center;
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

.password-change-confirmation-modal .registration-link-modal-footer .modal-confirm, .password-change-confirmation-modal .registration-link-modal-footer .modal-cancel {
  width: 25%;
  font-size: var(--font-size-m);
  border-radius: 4px;
  margin-left: 1em;
  padding-top: .3em;
  padding-bottom: .1em;
}

.password-change-confirmation-modal .modal-confirm {
  color: var(--confirm-foreground);
  background-color: var(--confirm-background);
  border: 1px solid var(--confirm-foreground);
}

.password-change-confirmation-modal .modal-cancel {
  color: var(--cancel-foreground);
  background-color: var(--cancel-background);
  border: 1px solid var(--confirm-foreground);
}

div.modal-confirm:disabled, div.modal-cancel:disabled {
  background-color: var(--grey);
}

:root {
  --text-field-height: 1.6em;
}

.login-and-discover-the-portal {
  height: 2em;
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
  margin-top: 2em;
  font-weight: 700;
}

.login-area {
  min-width: 30vw;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.login {
  letter-spacing: 0;
}

.please-enter-your-username-and-password {
  min-height: 3em;
  min-width: 35vw;
}

.username, .password {
  background-color: var(--grey);
  height: 4.5em;
  min-width: 35vw;
  padding: calc((var(--text-field-height)) / 4);
  border-radius: .7em;
  justify-content: center;
  align-items: flex-start;
  margin-left: .1em;
  display: flex;
  position: relative;
}

.password {
  margin-top: calc((var(--text-field-height)) / 2);
  padding-top: calc((var(--text-field-height)) / 2);
}

.username-input, .password-input {
  color: var(--black);
  background-color: var(--grey);
  align-self: center;
  font-weight: 400;
}

input.username-input, input.password-input {
  width: 70%;
  border: 0;
  margin-left: 0;
  position: relative;
}

.profile-filled-2, .vector-30 {
  height: calc((var(--text-field-height)) * 1.2);
  width: calc((var(--text-field-height)) * 1.2);
  align-self: center;
  margin-left: 20%;
  position: relative;
}

.vector-30 {
  margin-top: -2%;
}

.forgot-password {
  color: var(--primary-text);
  min-height: 2em;
  min-width: 10vw;
  margin-top: 9px;
  margin-left: 269px;
  font-style: italic;
  font-weight: 400;
}

.no-account-yet-register {
  color: var(--primary-text);
  min-height: 2em;
  min-width: 18em;
  margin-top: .25em;
}

.logo-compass-group_zwart_goud_rgb-1 {
  width: 30%;
  z-index: 1;
  position: absolute;
  bottom: 10%;
  left: 35%;
}

.sla-parameters-1 {
  background-color: var(--white);
  height: 1024px;
  width: 1440px;
  position: relative;
  overflow: hidden;
}

.overlap-group6-1 {
  height: 1024px;
  width: 1441px;
  position: absolute;
  top: 0;
  left: 0;
}

.rectangle-42 {
  height: 490px;
  object-fit: cover;
  width: 581px;
  position: absolute;
  top: 528px;
  left: 155px;
}

.performance-objectives {
  height: 735px;
  width: 537px;
  position: absolute;
  top: 275px;
  left: 785px;
}

.overlap-group3-4 {
  height: 735px;
  width: 537px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.sustainbility {
  min-height: 264px;
  width: 464px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 405px;
  left: 23px;
}

.benchmark-year {
  height: 46px;
  min-width: 464px;
  align-items: flex-start;
  margin-top: 4px;
  display: flex;
  position: relative;
}

.overlap-group-19 {
  height: 35px;
  width: 69px;
  border-radius: 8px;
  margin-top: 1px;
  margin-left: 135px;
  position: relative;
}

.number-6, .percent, .percent-1 {
  color: var(--teak);
  height: 34px;
  text-align: center;
  width: 47px;
  font-weight: 400;
  position: absolute;
  top: 1px;
  left: 11px;
}

.line-16 {
  height: 1px;
  width: 263px;
  margin-top: 4px;
  margin-left: -3px;
}

.food-waste {
  height: 46px;
  min-width: 464px;
  align-items: flex-end;
  margin-top: 16px;
  display: flex;
  position: relative;
}

.overlap-group-20 {
  height: 35px;
  width: 69px;
  border-radius: 8px;
  margin-left: 135px;
  position: relative;
}

.food {
  height: 46px;
  min-width: 464px;
  align-items: flex-end;
  margin-top: 15px;
  display: flex;
  position: relative;
}

.group-30 {
  height: 35px;
  width: 69px;
  border-radius: 8px;
  margin-bottom: 1px;
  margin-left: 135px;
}

.group-31 {
  height: 35px;
  width: 69px;
  border-radius: 8px;
  margin-left: 135px;
}

.scr {
  height: 72px;
  width: 464px;
  position: absolute;
  top: 295px;
  left: 23px;
}

.products {
  height: 73px;
  width: 465px;
  position: absolute;
  top: 181px;
  left: 22px;
}

.overlap-group-21 {
  height: 35px;
  width: 69px;
  border-radius: 8px;
  position: absolute;
  top: 29px;
  left: 396px;
}

.customer-satisfaction {
  height: 79px;
  width: 464px;
  position: absolute;
  top: 59px;
  left: 23px;
}

.overlap-group2-5 {
  height: 33px;
  width: 325px;
  position: absolute;
  top: 0;
  left: 0;
}

.vector-31 {
  cursor: pointer;
  height: 14px;
  width: 14px;
  position: absolute;
  top: 0;
  left: 177px;
}

.filters {
  height: 31px;
  min-width: 361px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 229px;
  left: 961px;
}

.company-abc-2 {
  background-color: var(--white);
  cursor: pointer;
  height: 31px;
  min-width: 141px;
  border-radius: 5px;
  align-items: center;
  padding: 0 10px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.apartment_fill0_wght300_grad0_opsz48-1 {
  height: 23px;
  width: 23px;
  align-self: flex-end;
}

.company-abc-3 {
  color: var(--teak);
  min-height: 20px;
  min-width: 92px;
  margin-bottom: 1px;
  margin-left: 1px;
  font-weight: 400;
}

.location-z-3 {
  cursor: pointer;
  min-width: 122px;
  align-items: flex-start;
  margin-left: 6px;
  display: flex;
}

.overlap-group-22 {
  background-color: var(--white);
  height: 31px;
  min-width: 120px;
  border-radius: 5px;
  align-items: flex-start;
  padding: 5px 11.2px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.vector-32 {
  height: 18px;
  width: 13px;
  align-self: center;
  margin-bottom: 1px;
}

.location-z-4 {
  color: var(--teak);
  min-height: 20px;
  margin-left: 11px;
  font-weight: 400;
}

.x2022-2 {
  background-color: var(--white);
  cursor: pointer;
  height: 31px;
  min-width: 88px;
  border-radius: 5px;
  align-items: flex-start;
  margin-left: 4px;
  padding: 4px 8px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.calendar_today_fill0 {
  height: 22px;
  width: 22px;
}

.number-7 {
  color: var(--teak);
  min-height: 20px;
  min-width: 33px;
  align-self: center;
  margin-bottom: 1px;
  margin-left: 6px;
  font-weight: 400;
}

.input-variables {
  height: 222px;
  width: 573px;
  position: absolute;
  top: 273px;
  left: 159px;
}

.overlap-group4-1 {
  height: 222px;
  width: 573px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.overlap-group2-6 {
  height: 35px;
  width: 363px;
  position: absolute;
  top: 74px;
  left: 177px;
}

.rectangle-28 {
  height: 35px;
  width: 358px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.fill-in-to-know-your-capture-rate {
  color: var(--teak);
  height: 34px;
  width: 353px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 10px;
}

.logo-compass-group_zwart_rgb-1-4 {
  cursor: pointer;
  height: 40px;
  object-fit: cover;
  width: 96px;
}

.vector-33 {
  cursor: pointer;
  height: 27px;
  width: 27px;
  margin-top: 3px;
  margin-left: 1060px;
}

.vector-34 {
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-left: 15px;
}

.name-10 {
  letter-spacing: 0;
  min-height: 40px;
  align-self: flex-start;
  margin-left: 15px;
}

.span1-6 {
  color: var(--primary-text);
  font-weight: 400;
}

.sla-parameters-filled-1 {
  height: 32px;
  width: 32px;
  position: absolute;
  top: 301px;
  left: 17px;
}

.cancel, .cancel-2 {
  height: 3em;
  min-width: 9em;
}

.cancel-1, .cancel-3 {
  height: 1.5em;
  letter-spacing: 0;
  min-width: 4em;
  text-align: center;
}

.overlap-group-6 {
  height: 3em;
  min-width: 9em;
  border-radius: .7em;
  align-items: flex-start;
  padding: .6em 2.5em;
  display: flex;
}

.cancel-and-save-changes-1 {
  height: min-content;
  width: min-content;
  align-items: flex-start;
  gap: 10px;
  display: flex;
  position: absolute;
  top: 683px;
  left: 609px;
}

.yes-im-sure {
  cursor: pointer;
  height: 33px;
  min-width: 108px;
  margin-right: -2px;
}

.overlap-group1-3 {
  background-color: var(--husk);
  height: 33px;
  min-width: 106px;
  border-radius: 8px;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 7px 10px;
  display: flex;
}

.save-changes-1 {
  height: 18px;
  letter-spacing: 0;
  min-width: 85px;
  text-align: center;
}

.cancel-and-save-changes-1.cancel-and-save-changes-2 {
  top: 694px;
  left: 265px;
}

.cancel-and-save-changes-1.cancel-and-save-changes-2 .overlap-group1-3, .cancel-and-save-changes-1.cancel-and-save-changes-3 .overlap-group1-3 {
  padding: 7px 8px;
}

.cancel-and-save-changes-1.cancel-and-save-changes-2 .save-changes-1, .cancel-and-save-changes-1.cancel-and-save-changes-3 .save-changes-1 {
  min-width: 89px;
}

.cancel-and-save-changes-1.cancel-and-save-changes-3 {
  cursor: pointer;
  top: 159px;
  left: 313px;
}

.cancel-and-save-changes-1.cancel-and-save-changes-3 .yes-im-sure {
  cursor: unset;
}

.frame-1-4 {
  height: min-content;
  width: 18%;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.desktop-bodycopy-20pt-calibri-regular-2 {
  color: var(--primary-text);
  width: 16%;
  margin-top: -1px;
  font-weight: 400;
}

.desktop-bodycopy-18pt-calibri-regular-1 {
  color: var(--secondary-text);
  width: 18%;
  font-weight: 400;
}

.frame-1-4.frame-1-1 {
  height: min-content;
  width: 16%;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 2.2em;
  left: 0;
}

.frame-1-4.frame-1-2 {
  height: min-content;
  width: 16%;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 2.3em;
  left: 1px;
}

.frame-1-4.frame-1-3 {
  height: min-content;
  width: 16%;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 3em;
  left: 0;
}

.fill-in-box-2 {
  height: 3em;
  width: 5.75em;
  border-radius: .7em;
  position: absolute;
  top: 0;
  left: 0;
}

.fill-in-box-2.fill-in-box-1 {
  top: 3em;
  left: 27.5%;
}

.inner-content-2-1 {
  height: 46px;
  min-width: 230px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 74px;
  left: 24px;
}

.innter-content-3-1 {
  min-height: 25px;
  width: 325px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.desktop-bodycopy-20pt-calibri-regular-1 {
  color: var(--primary-text);
  min-height: 24px;
  margin-top: -1px;
  font-weight: 400;
}

.line-3 {
  height: 1px;
  width: 100px;
  margin-top: 2px;
}

.innter-content-3-1.innter-content-3 {
  position: absolute;
  top: 0;
  left: 0;
}

.innter-content-3-1.innter-content-3-2 {
  position: absolute;
  top: 8px;
  left: 0;
}

.search-bar {
  width: 1440px;
  align-items: flex-start;
  display: flex;
}

.overlay .search-bar {
  background-color: #0000;
}

.search-bar-1 {
  height: 1024px;
  width: 1440px;
  align-items: flex-start;
  padding: 0 227px;
  display: flex;
  position: relative;
  top: calc(50vh - 512px);
  left: calc(50vw - 720px);
}

.search-bar-2 {
  height: 66px;
  width: 605px;
  margin-left: 381px;
}

.overlap-group-23 {
  background-color: var(--white);
  height: 66px;
  min-width: 603px;
  border-radius: 23px;
  align-items: flex-end;
  padding: 12px 22px;
  display: flex;
}

.vector-35 {
  height: 36px;
  width: 36px;
  margin-bottom: 1px;
}

.search-for-documents {
  color: #e5dec8;
  height: 40px;
  width: 494px;
  margin-left: 9px;
  font-weight: 400;
}

#overlay-search-bar {
  background-color: #0000;
}

.company-filter-documents-sla {
  width: 1440px;
  align-items: flex-start;
  display: flex;
}

.overlay .company-filter-documents-sla {
  background-color: #0000;
}

.company-filter-documents-sla-1 {
  height: 1024px;
  width: 1440px;
  flex-direction: column;
  align-items: flex-end;
  padding: 229px 276px;
  display: flex;
  position: relative;
  top: calc(50vh - 512px);
  left: calc(50vw - 720px);
}

.overlap-group1-7 {
  background-color: var(--white);
  height: 31px;
  min-width: 141px;
  border-radius: 5px;
  align-items: center;
  margin-right: 62px;
  padding: 5px 8.1px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.vector-36 {
  height: 17px;
  width: 17px;
  margin-bottom: .34px;
}

.company-abc-4 {
  color: var(--teak);
  min-height: 20px;
  min-width: 92px;
  align-self: flex-end;
  margin-left: 9px;
  font-weight: 400;
}

.overlap-group-24 {
  background-color: var(--white);
  min-height: 81px;
  width: 203px;
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 7px;
  padding: 11px 15px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.flex-row-16 {
  height: 21px;
  min-width: 118px;
  align-items: flex-start;
  margin-top: 3px;
  display: flex;
}

.vector-37 {
  height: 20px;
  width: 20px;
}

.company-abc-5 {
  color: var(--teak);
  min-height: 20px;
  min-width: 92px;
  align-self: flex-end;
  margin-left: 6px;
  font-weight: 400;
}

.line-18-3 {
  height: 1px;
  width: 146px;
  margin-top: 6px;
}

.flex-row-17 {
  height: 20px;
  min-width: 108px;
  align-items: center;
  margin-top: 8px;
  margin-left: 3.25px;
  display: flex;
}

.vector-38 {
  cursor: pointer;
  height: 14px;
  width: 14px;
  margin-bottom: 2px;
}

.add-company {
  color: var(--teak);
  cursor: pointer;
  min-height: 20px;
  min-width: 89px;
  margin-left: 6px;
  font-weight: 400;
}

#overlay-company-filter-documents-sla {
  background-color: #0000;
}

#overlay-changes-saved, .overlay .changes-saved {
  background-color: #0000;
}

.changes-saved {
  width: 100%;
  align-items: flex-start;
  display: flex;
}

.changes-saved-1 {
  height: 100vh;
  width: 1vh;
  align-items: center;
  padding: 0 .35vh;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

.overlap-group-25 {
  height: .5vh;
  width: 50vw;
  margin-left: 1px;
  position: relative;
}

.vector-57 {
  height: 4.7em;
  width: 6.5em;
  position: absolute;
  top: .1vh;
  left: .225vw;
}

.location-filter-documents-sla {
  width: 1440px;
  align-items: flex-start;
  display: flex;
}

.overlay .location-filter-documents-sla {
  background-color: #0000;
}

.location-filter-documents-sla-1 {
  height: 1024px;
  width: 1440px;
  flex-direction: column;
  align-items: flex-end;
  padding: 229px 129px;
  display: flex;
  position: relative;
  top: calc(50vh - 512px);
  left: calc(50vw - 720px);
}

.overlap-group-26 {
  background-color: var(--white);
  height: 31px;
  min-width: 120px;
  border-radius: 5px;
  align-items: center;
  margin-right: 83px;
  padding: 5px 11.2px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.vector-48 {
  height: 18px;
  width: 13px;
  margin-bottom: 1px;
}

.location-z-5 {
  color: var(--teak);
  min-height: 20px;
  min-width: 67px;
  align-self: flex-end;
  margin-left: 11px;
  font-weight: 400;
}

.overlap-group1-8 {
  background-color: var(--white);
  min-height: 162px;
  width: 203px;
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 7px;
  padding: 12px 17px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.flex-row-18 {
  height: 21px;
  min-width: 94px;
  align-items: flex-start;
  margin-top: 2px;
  display: flex;
}

.vector-47 {
  height: 20px;
  width: 20px;
}

.location-1 {
  color: var(--teak);
  min-height: 20px;
  min-width: 68px;
  align-self: flex-end;
  margin-left: 6px;
  font-weight: 400;
}

.flex-row-19 {
  height: 21px;
  min-width: 94px;
  align-items: flex-start;
  margin-top: 5px;
  display: flex;
}

.flex-row-20 {
  height: 21px;
  min-width: 128px;
  align-items: flex-end;
  margin-top: 5px;
  display: flex;
}

.location-c {
  color: var(--teak);
  min-height: 20px;
  min-width: 68px;
  margin-left: 6px;
  font-weight: 400;
}

.vector-49 {
  height: 19px;
  width: 15px;
  align-self: flex-start;
  margin-left: 19px;
}

.flex-row-21 {
  height: 21px;
  min-width: 93px;
  align-items: flex-start;
  margin-top: 5px;
  display: flex;
}

.location-z-6 {
  color: var(--teak);
  min-height: 20px;
  min-width: 67px;
  align-self: flex-end;
  margin-left: 6px;
  font-weight: 400;
}

.line-18-4 {
  height: 1px;
  width: 146px;
  margin-top: 8px;
}

.flex-row-22 {
  height: 20px;
  min-width: 102px;
  align-items: center;
  margin-top: 8px;
  margin-left: 3.25px;
  display: flex;
}

.vector-50 {
  cursor: pointer;
  height: 14px;
  width: 14px;
  margin-bottom: 2px;
}

.add-location-1 {
  color: var(--teak);
  cursor: pointer;
  min-height: 20px;
  min-width: 82px;
  margin-left: 6px;
  font-weight: 400;
}

#overlay-location-filter-documents-sla {
  background-color: #0000;
}

.enhance-service-quality {
  width: 1440px;
  align-items: flex-start;
  display: flex;
}

.overlay .enhance-service-quality {
  background-color: #0000;
}

.enhance-service-quality-1 {
  height: 1024px;
  width: 1440px;
  align-items: center;
  padding: 0 356px;
  display: flex;
  position: relative;
  top: calc(50vh - 512px);
  left: calc(50vw - 720px);
}

.overlap-group-27 {
  height: 500px;
  width: 727px;
  position: relative;
}

.qr-code-icon {
  height: 64px;
  width: 64px;
  position: absolute;
  top: 102px;
  left: 332px;
}

#overlay-enhance-service-quality {
  background-color: #0000;
}

.overlap-group-main {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.reports-list-container, .reports-message-text, .performance-dashboard {
  position: relative;
}

.reports-list-container, .reports-message-text {
  width: 55vw;
  top: 18vh;
  left: 10vw;
}

.reports-message-text {
  font-family: var(--font-family-calibri-regular);
  font-size: var(--font-size-s);
  margin-top: 1em;
  font-weight: 400;
}

.reports-list {
  flex-flow: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.reports-list-item {
  background-color: var(--caramel);
  cursor: pointer;
  height: 3em;
  text-align: center;
  vertical-align: middle;
  font-family: var(--font-family-calibri-regular);
  font-size: var(--font-size-s);
  border-radius: .85em;
  flex: 1 0 15vw;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  padding-top: .5em;
  padding-bottom: .5em;
  font-weight: 400;
}

.performance-dashboard {
  background-color: var(--white);
}

.push-down-7 {
  margin-top: 7vh !important;
}

.powerbi-report-container {
  border-radius: .85em;
  position: absolute;
  inset: 18vh 1vw 1.5vw 10vw;
}

.caramel-background {
  background-color: var(--caramel);
}

.full-size {
  width: 100%;
  height: 100%;
}

.align-right {
  text-align: right;
  padding-top: .2em;
  padding-bottom: .2em;
  padding-right: 1em;
}

.company-filter {
  width: 1440px;
  align-items: flex-start;
  display: flex;
}

.overlay .company-filter {
  background-color: #0000;
}

.company-filter-1 {
  height: 1024px;
  width: 1440px;
  flex-direction: column;
  align-items: flex-end;
  padding: 229px 374px;
  display: flex;
  position: relative;
  top: calc(50vh - 512px);
  left: calc(50vw - 720px);
}

.overlap-group-32 {
  background-color: var(--white);
  height: 31px;
  min-width: 141px;
  border-radius: 5px;
  align-items: center;
  margin-right: 62px;
  padding: 5px 8.1px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.vector-55 {
  height: 17px;
  width: 17px;
  margin-bottom: .34px;
}

.company-abc-8 {
  color: var(--teak);
  min-height: 20px;
  min-width: 92px;
  align-self: flex-end;
  margin-left: 9px;
  font-weight: 400;
}

.filter-options-2 {
  background-color: var(--white);
  min-height: 81px;
  width: 203px;
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 7px;
  padding: 12px 15px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.flex-row-23 {
  min-width: 118px;
  align-items: flex-start;
  margin-top: 2px;
  display: flex;
}

.vector-56 {
  height: 20px;
  width: 20px;
}

.company-abc-9 {
  color: var(--teak);
  min-height: 20px;
  margin-left: 6px;
  font-weight: 400;
}

.line-18-5 {
  height: 1px;
  width: 146px;
  margin-top: 8px;
}

.flex-row-24 {
  height: 20px;
  min-width: 108px;
  align-items: center;
  margin-top: 6px;
  margin-left: 3.25px;
  display: flex;
}

.vector-57 {
  cursor: pointer;
  height: 14px;
  width: 14px;
}

.add-company-1 {
  color: var(--teak);
  cursor: pointer;
  min-height: 20px;
  min-width: 89px;
  margin-left: 6px;
  font-weight: 400;
}

#overlay-company-filter {
  background-color: #0000;
}

/*# sourceMappingURL=index.3b15fc63.css.map */
