.cancel,
.cancel-2 {
  height: 3em;
  min-width: 9em;
}

.cancel-1,
.cancel-3 {
  height: 1.5em;
  letter-spacing: 0;
  min-width: 4em;
  text-align: center;
}

.overlap-group-6 {
  align-items: flex-start;
  border-radius: 0.7em;
  display: flex;
  height: 3em;
  min-width: 9em;
  padding: 0.6em 2.5em;
}
