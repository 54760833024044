:root {
  --font-size-xl: 2.8vh;
  --font-size-l: 2.2vh;
  --font-size-m: 1.8vh;
  --font-size-s: 1.6vh;
  --font-size-xs: 1.3vh;
  --font-size-xxs: 1.1vh;
  --font-size-xxxs: 0.95vh;

  --font-family-calibri-bold: "Calibri-Bold", Helvetica;
  --font-family-calibri-italic: "Calibri-Italic", Helvetica;
  --font-family-calibri-regular: "Calibri-Regular", Helvetica;
  --font-family-merriweather_sans: "Merriweather Sans", Helvetica;
  --font-family-pt_serif: "PT Serif", Helvetica;
}

.desktopbodycopy {
  font-family: var(--font-family-calibri-regular);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
}

.desktopbodycopy-italic {
  font-family: var(--font-family-calibri-italic);
  font-size: var(--font-size-m);
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
}

.desktopbodycopy-s {
  font-family: var(--font-family-calibri-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
}

.desktopfilters {
  font-family: var(--font-family-calibri-regular);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
}

.calibri-bold-black-xs {
  color: var(--primary-text);
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.calibri-normal-black-xs {
  color: var(--primary-text);
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.calibri-bold-black-xxs {
  color: var(--primary-text);
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 700;
}

.calibri-normal-black-xxs {
  color: var(--primary-text);
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.merriweathersans-normal-white-xxxs {
  color: var(--white);
  font-family: var(--font-family-merriweather_sans);
  font-size: var(--font-size-xxxs);
  font-style: normal;
  font-weight: 400;
}

.ptserif-bold-black-xl {
  color: var(--primary-text);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.ptserif-bold-husk-xl {
  color: var(--husk);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.ptserif-bold-black-l {
  color: var(--primary-text);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.ptserif-bold-white-l {
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.ptserif-normal-black-l {
  color: var(--primary-text);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-l);
  font-style: italic;
  font-weight: 400;
}

.ptserif-normal-husk-l {
  color: var(--husk);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-l);
  font-style: italic;
  font-weight: 400;
}

.calibri-bold-white-m {
  color: var(--white);
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.ptserif-bold-white-xs {
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.ptserif-normal-black-xs {
  color: var(--primary-text);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xxs);
  font-style: italic;
  font-weight: 400;
}

.ptserif-bold-white-xxs {
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 700;
}

.ptserif-bold-husk-xxs {
  color: var(--husk);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 700;
}
