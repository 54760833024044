.location-filter-documents-sla {
  align-items: flex-start;
  display: flex;
  width: 1440px;
}

.overlay .location-filter-documents-sla {
  background-color: #00000000;
}

.location-filter-documents-sla-1 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 1024px;
  left: calc(50vw - 720.0px);
  padding: 229px 129px;
  position: relative;
  top: calc(50vh - 512.0px);
  width: 1440px;
}

.overlap-group-26 {
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  height: 31px;
  margin-right: 83px;
  min-width: 120px;
  padding: 5px 11.2px;
}

.vector-48 {
  height: 18px;
  margin-bottom: 1.0px;
  width: 13px;
}

.location-z-5 {
  align-self: flex-end;
  color: var(--teak);
  font-weight: 400;
  margin-left: 11px;
  min-height: 20px;
  min-width: 67px;
}

.overlap-group1-8 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  min-height: 162px;
  padding: 12px 17px;
  width: 203px;
}

.flex-row-18 {
  align-items: flex-start;
  display: flex;
  height: 21px;
  margin-top: 2px;
  min-width: 94px;
}

.vector-47 {
  height: 20px;
  width: 20px;
}

.location-1 {
  align-self: flex-end;
  color: var(--teak);
  font-weight: 400;
  margin-left: 6px;
  min-height: 20px;
  min-width: 68px;
}

.flex-row-19 {
  align-items: flex-start;
  display: flex;
  height: 21px;
  margin-top: 5px;
  min-width: 94px;
}

.flex-row-20 {
  align-items: flex-end;
  display: flex;
  height: 21px;
  margin-top: 5px;
  min-width: 128px;
}

.location-c {
  color: var(--teak);
  font-weight: 400;
  margin-left: 6px;
  min-height: 20px;
  min-width: 68px;
}

.vector-49 {
  align-self: flex-start;
  height: 19px;
  margin-left: 19px;
  width: 15px;
}

.flex-row-21 {
  align-items: flex-start;
  display: flex;
  height: 21px;
  margin-top: 5px;
  min-width: 93px;
}

.location-z-6 {
  align-self: flex-end;
  color: var(--teak);
  font-weight: 400;
  margin-left: 6px;
  min-height: 20px;
  min-width: 67px;
}

.line-18-4 {
  height: 1px;
  margin-top: 8px;
  width: 146px;
}

.flex-row-22 {
  align-items: center;
  display: flex;
  height: 20px;
  margin-left: 3.25px;
  margin-top: 8px;
  min-width: 102px;
}

.vector-50 {
  cursor: pointer;
  height: 14px;
  margin-bottom: 2.0px;
  width: 14px;
}

.add-location-1 {
  color: var(--teak);
  cursor: pointer;
  font-weight: 400;
  margin-left: 6px;
  min-height: 20px;
  min-width: 82px;
}

#overlay-location-filter-documents-sla {
  background-color: transparent;
}

