.inner-subjects {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  height: min-content;
  left: 39px;
  position: absolute;
  top: 15px;
  width: 544px;
}

.desktop-h4-header-2 {
  height: 31px;
  letter-spacing: 0;
  margin-top: -1px;
  width: 485px;
}

.line-2 {
  height: 2px;
  margin-bottom: -1px;
  min-width: 64px;
}

.inner-subjects.inner-subjects-1 {
  left: 31px;
  width: 434px;
}

.inner-subjects.inner-subjects-1 .desktop-h4-header-2 {
  margin-right: -51px;
}

.inner-subjects.inner-subjects-2 {
  left: 25px;
  top: 17px;
  width: min-content;
}

.inner-subjects.inner-subjects-3 {
  left: 26px;
  top: 16px;
  width: min-content;
}

.inner-subjects.inner-subjects-4 {
  left: 25px;
  top: 16px;
  width: min-content;
}

.inner-subjects.inner-subjects-5 {
  left: 20px;
  top: 16px;
  width: min-content;
}

.inner-subjects.inner-subjects-6 {
  left: 24px;
  top: 18px;
  width: min-content;
}

