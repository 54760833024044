.company-filter {
  align-items: flex-start;
  display: flex;
  width: 1440px;
}

.overlay .company-filter {
  background-color: #00000000;
}

.company-filter-1 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 1024px;
  left: calc(50vw - 720.0px);
  padding: 229px 374px;
  position: relative;
  top: calc(50vh - 512.0px);
  width: 1440px;
}

.overlap-group-32 {
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  height: 31px;
  margin-right: 62px;
  min-width: 141px;
  padding: 5px 8.1px;
}

.vector-55 {
  height: 17px;
  margin-bottom: 0.34px;
  width: 17px;
}

.company-abc-8 {
  align-self: flex-end;
  color: var(--teak);
  font-weight: 400;
  margin-left: 9px;
  min-height: 20px;
  min-width: 92px;
}

.filter-options-2 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  min-height: 81px;
  padding: 12px 15px;
  width: 203px;
}

.flex-row-23 {
  align-items: flex-start;
  display: flex;
  margin-top: 2px;
  min-width: 118px;
}

.vector-56 {
  height: 20px;
  width: 20px;
}

.company-abc-9 {
  color: var(--teak);
  font-weight: 400;
  margin-left: 6px;
  min-height: 20px;
}

.line-18-5 {
  height: 1px;
  margin-top: 8px;
  width: 146px;
}

.flex-row-24 {
  align-items: center;
  display: flex;
  height: 20px;
  margin-left: 3.25px;
  margin-top: 6px;
  min-width: 108px;
}

.vector-57 {
  cursor: pointer;
  height: 14px;
  width: 14px;
}

.add-company-1 {
  color: var(--teak);
  cursor: pointer;
  font-weight: 400;
  margin-left: 6px;
  min-height: 20px;
  min-width: 89px;
}

#overlay-company-filter {
  background-color: transparent;
}

