.search-bar {
  align-items: flex-start;
  display: flex;
  width: 1440px;
}

.overlay .search-bar {
  background-color: #00000000;
}

.search-bar-1 {
  align-items: flex-start;
  display: flex;
  height: 1024px;
  left: calc(50vw - 720.0px);
  padding: 0 227px;
  position: relative;
  top: calc(50vh - 512.0px);
  width: 1440px;
}

.search-bar-2 {
  height: 66px;
  margin-left: 381px;
  width: 605px;
}

.overlap-group-23 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 23px;
  display: flex;
  height: 66px;
  min-width: 603px;
  padding: 12px 22px;
}

.vector-35 {
  height: 36px;
  margin-bottom: 1px;
  width: 36px;
}

.search-for-documents {
  color: #e5dec8;
  font-weight: 400;
  height: 40px;
  margin-left: 9px;
  width: 494px;
}

#overlay-search-bar {
  background-color: transparent;
}

