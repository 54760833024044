.editable-table {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

div.MuiToolbar-root {
  background-color: var(--caramel);
}

div.MuiToolbar-root .MuiButton-root {
  margin-left: 1em;
  margin-top: 0.1em;
  margin-bottom: 0.2em;
  background-color: var(--husk);
}

div.MuiToolbar-root .MuiBox-root {
  margin-top: 0.1em;
}

button.MuiIconButton-root {
  color: var(--husk);
}

div.MuiDialogActions-root button.MuiButton-textPrimary,
div.MuiDialogActions-root button.MuiButton-textSecondary {
  color: var(--husk);
  font-weight: 700;
}

div.MuiDialogActions-root button.MuiButton-containedPrimary,
div.MuiDialogActions-root button.MuiButton-containedSecondary {
  background-color: var(--husk);
  color: var(--white);
  font-weight: 700;
}

.dialog-title {
  color: var(--husk);
}

tr td:nth-child(1) button {
    margin-left: -0.2em;
}
tr td:nth-child(1) button:nth-child(1) {
    margin-left: 0.1em;
}

th.MuiTableCell-root.MuiTableCell-head:nth-child(1) div.Mui-TableHeadCell-Content {
    left: -1em;
}
