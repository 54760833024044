:root {
  --image-width: 33px;
  --image-height: 37px;
  --date-width: 8em;
  --document-name-width: 50%;
}

.document-page {
  position: relative;
  overflow: hidden;
  background-color: var(--white);
}

.contracts {
  position: absolute;
  top: 25vh;
  left: 10vw;
  height: 25em;
  min-width: 30vw;
}

.overlap-group10 {
  position: relative;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  min-width: 30vw;
}

.arrow-forward-header {
  align-self: center;
  height: 1.5em;
  margin-top: 0.2em;
  margin-left: 1em;
  margin-right: 1em;
  width: 0.6em;
}

.document-status-icon {
  float: right;
  margin-right: 15%;
  height: 60%;
}

.document-status-icon img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.document-icon {
  align-self: center;
  margin: 0.2em;
  height: var(--image-height);
  width: var(--image-width);
  object-fit: cover;
}

.document-filename,
.document-date,
.document-size {
  position: absolute;
}

.document-date,
.document-size {
  font-weight: 400;
  color: var(--husk);
}

.document-filename,
.document-size {
  left: calc(var(--image-width) + 0.5em);
  font-weight: 400;
}

.document-filename {
  position: absolute;
  top: 0;
  min-width: 50%;
  color: var(--primary-text);
  font-weight: 400;
}

.document-date {
  top: 0.2em;
  left: calc(var(--image-width) + var(--document-name-width));
  width: var(--date-width);
}

.document-size {
  padding-left: 0.4em;
  top: 1.8em;
}

.flex-row {
  position: absolute;
  display: flex;
  align-items: flex-start;
  height: 4em;
  min-width: 25em;
}

.flex-row-inner {
  position: relative;
  left: 3em;
  height: 3.5em;
  width: 100%;
  margin-left: 1em;
}

.row-1 {
  top: 4em;
}
.row-2 {
  top: 9em;
}
.row-3 {
  top: 14em;
}
.row-4 {
  top: 19em;
}
.row-5 {
  top: 24em;
}

.width-50 {
  width: 100%;
  min-width: 50vw;
}
