#overlay-changes-saved {
  background-color: transparent;
}

.overlay .changes-saved {
  background-color: #00000000;
}

.changes-saved {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.changes-saved-1 {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 0 0.35vh;
  top: 0;
  left: 0;
  width: 1vh;
}

.overlap-group-25 {
  position: relative;
  height: 0.5vh;
  margin-left: 1px;
  width: 50vw;
}

.vector-57 {
  position: absolute;
  height: 4.7em;
  top: .1vh;
  left: 0.225vw;
  width: 6.5em;
}
