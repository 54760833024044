.type-titles {
  position: absolute;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 7vh;
  top: 10vh;
  left: 32vw;
  width: 70%;
}

.welcome-header {
  flex: 1;
  letter-spacing: 0;
  margin-top: -1px;
}

.subtitle {
  flex: 1;
  letter-spacing: 0;
}

.type-titles.type-titles-0 {
  left: 35vw;
  width: 65vw;
  padding-left: 4vw;
}

.type-titles.type-titles-1,
.type-titles.type-titles-2,
.type-titles.type-titles-3,
.type-titles.type-titles-4 {
  left: 10vw;
}
