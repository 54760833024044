.frame-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 15%;
  width: 37%;
}

.text {
  align-self: stretch;
  flex: 1;
  letter-spacing: 0;
  margin-top: -1px;
  text-align: center;
}

.explanation {
  align-self: stretch;
  flex: 1;
  letter-spacing: 0;
  text-align: center;
}

.frame-4.frame-4-1,
.frame-4.frame-4-2,
.frame-4.frame-4-3 {
  margin-top: 15em;
}

