.thank-you {
  align-items: flex-start;
  display: flex;
  width: 1440px;
}

.overlay .thank-you {
  background-color: #00000000;
}

.thank-you-1 {
  align-items: center;
  display: flex;
  height: 1024px;
  left: calc(50vw - 720.0px);
  padding: 0 356px;
  position: relative;
  top: calc(50vh - 512.0px);
  width: 1440px;
}

.overlap-group-17 {
  height: 500px;
  position: relative;
  width: 727px;
}

.mail {
  height: 61px;
  left: 325px;
  position: absolute;
  top: 103px;
  width: 77px;
}

#overlay-thank-you {
  background-color: transparent;
}

