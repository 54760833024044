.years-filter-documents-sla {
  align-items: flex-start;
  display: flex;
  width: 1440px;
}

.overlay .years-filter-documents-sla {
  background-color: #00000000;
}

.years-filter-documents-sla-1 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 1024px;
  left: calc(50vw - 720.0px);
  padding: 229px 3px;
  position: relative;
  top: calc(50vh - 512.0px);
  width: 1440px;
}

.x2022-1 {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  margin-right: 113px;
  min-width: 90px;
}

.overlap-group1-5 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  height: 31px;
  min-width: 88px;
  padding: 5px 11.2px;
}

.vector-28 {
  align-self: center;
  height: 18px;
  margin-bottom: 1.11px;
  width: 16px;
}

.number-4 {
  color: var(--teak);
  font-weight: 400;
  margin-left: 9px;
  min-height: 20px;
}

.overlap-group-16 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  min-height: 183px;
  padding: 13px 15px;
  width: 203px;
}

.flex-row-10 {
  align-items: flex-start;
  display: flex;
  height: 21px;
  margin-top: 1px;
  min-width: 65px;
}

.vector-27 {
  height: 20px;
  width: 20px;
}

.number-3 {
  align-self: flex-end;
  color: var(--teak);
  font-weight: 400;
  margin-left: 12px;
  min-height: 20px;
  min-width: 33px;
}

.flex-row-11 {
  align-items: flex-start;
  display: flex;
  height: 21px;
  margin-top: 5px;
  min-width: 65px;
}

.flex-row-12 {
  align-items: flex-start;
  display: flex;
  height: 21px;
  margin-top: 5px;
  min-width: 64px;
}

.number-5 {
  align-self: flex-end;
  color: var(--teak);
  font-weight: 400;
  margin-left: 11px;
  min-height: 20px;
  min-width: 33px;
}

.flex-row-13 {
  align-items: flex-start;
  display: flex;
  height: 22px;
  margin-top: 4px;
  min-width: 65px;
}

.flex-row-14 {
  align-items: flex-start;
  display: flex;
  height: 21px;
  margin-top: 3px;
  min-width: 65px;
}

.line-18-2 {
  height: 1px;
  margin-top: 6px;
  width: 146px;
}

.flex-row-15 {
  align-items: center;
  display: flex;
  height: 20px;
  margin-left: 3.25px;
  margin-top: 6px;
  min-width: 112px;
}

.vector-29 {
  cursor: pointer;
  height: 14px;
  margin-bottom: 2.0px;
  width: 14px;
}

.custom-date-1 {
  color: var(--teak);
  cursor: pointer;
  font-weight: 400;
  margin-left: 15px;
  min-height: 20px;
  min-width: 83px;
}

#overlay-years-filter-documents-sla {
  background-color: transparent;
}

