.reports-overview-page {
  background-color: var(--white);
  overflow: hidden;
  position: relative;
}

.overlap-group-reports-overview {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.reports-table-container {
  position: absolute;
  width: 80vw;
  min-height: 8vh;
  max-height: 65vh;
  left: 10vw;
  top: 18vh;
}

.reports-table,
.reports-table-placeholder {
  position: relative;
  margin-bottom: 2em;
}

.reports-table-placeholder {
  height: 8vh;
}

.reports-message-modal {
  position: absolute;
  left: 30vw;
  top: 45vh;
  width: 40vw;
  z-index: 2;
  font-family: var(--font-family-calibri-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  color: var(--error-foreground);
  background-color: var(--caramel);
  padding: 1em;
  text-align: left;
  border-style: solid;
  border-color: var(--dask);
  border-width: 0.2em;
  overflow-wrap: break-word;
}

.reports-message-modal:hover {
  cursor: pointer;
}
