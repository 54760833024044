:root {
  --background-color: #f7f7f7;
  --caramel: #e1d8c3;
  --grey: #d5d5d5;
  --dark-grey: #808080;
  --husk: #b89d5f;
  --primary-text: #000000;
  --secondary-text: #888888;
  --teak: #af9762;
  --teak-transparent: #af976280;
  --white: #ffffff;
  --error-background: #ffaaaa;
  --error-foreground: #664444;
  --confirm-background: rgba(32, 255, 32, 0.5);
  --confirm-foreground: black;
  --cancel-background: rgba(255, 48, 32, 0.5);
  --cancel-foreground: black;
}
