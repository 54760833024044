:root {
  --text-field-height: 1.6em;
}

.registration-screen {
  display: flex;
  align-items: flex-start;
  background-color: var(--white);
}

.proposition-summary {
  align-items: flex-start;
  justify-content: flex-end;
  display: flex;
}

.overlap-group-registration-left,
.overlap-group-registration-right {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding-left: 6em;
  padding-right: 6em;
}

.overlap-group-registration-left {
   background-image: url(/static/img/rectangle-7@1x.svg);
}

.overlap-group-registration-right {
  background-color: var(--background-color);
}

.login-and-discover-the-portal {
  height: 2em;
  margin-top: 2em;
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xl);
  font-weight: 700;
  letter-spacing: 0;
}

.login-area {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  min-width: 30vw;
}

.register {
  letter-spacing: 0;
  margin-top: 2em;
  margin-bottom: 2em;
  left: -8em;
}

.registration-form-head {
  margin-top: 2em;
  margin-bottom: 1em;
}

.form-item {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--grey);
  border-radius: 0.7em;
  height: 4em;
  margin-left: 0.1em;
  margin-top: calc((var(--text-field-height)) / 2);
  min-width: 35vw;
  padding: calc((var(--text-field-height)) / 4);
  background-color: var(--caramel);
}

.form-item-readonly {
  height: 3.5em;
  padding-top: calc((var(--text-field-height)) * 0.5);
}

.form-item input {
  position: relative;
  align-self: center;
  color: var(--black);
  font-weight: 400;
  margin-left: 0.2em;
  border: 0;
  width: 80%;
  background-color: var(--background-color);
}

.form-item input:disabled {
  background-color: initial;
  margin-left: 0.5em;
}

.custom-icon {
  color: white;
  margin-top: calc((var(--text-field-height)) * 0.33);
  margin-left: 3em;
  margin-right: 1em;
}

.form-item-readonly .custom-icon {
  margin-top: calc((var(--text-field-height)) * 0.1);
  margin-bottom: calc((var(--text-field-height)) * 0.1);
}

.span1-5 {
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-m);
  font-weight: 700;
  letter-spacing: 0;
}

.logo-compass-group_zwart_goud_rgb-1 {
  position: absolute;
  width: 30%;
  bottom: 10%;
  left: 35%;
  z-index: 1;
}

.modal {
  position: absolute;
  left: 30vw;
  top: 40vh;
  width: 40vw;
  height: auto;
  z-index: 1;
  text-align: left;
  overflow-wrap: break-word;
  font-family: var(--font-family-calibri-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  padding: 1em;
  border-style: solid;
  border-width: 0.2em;

}

.modal.token-validation-error {
  height: auto;
  font-family: var(--font-family-calibri-bold);
  font-weight: 700;
  padding: 1em;
}

.modal.message-modal {
  text-align: center;
  color: var(--husk);
  background-color: var(--background-color);
  border-color: var(--teak);
}

.modal.error-modal {
  color: var(--error-foreground);
  background-color: var(--error-background);
  border-color: var(--error-foreground);
}
