.page-selector {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center
  justify-content: center;
  padding-left: 1em;
}

.page-selector-title,
.page-selector-subtitle,
.page-selector-image {
  position: relative;
  text-align: center;
  width: 100%;
}

.page-selector-title {
  width: 100%;
  top: 7%;
  letter-spacing: 0;
}

.page-selector-subtitle {
  position: absolute;
  font-weight: 400;
  padding-left: 20%;
  bottom: 2%;
  width: 80%;
  color: var(--primary-text);
}

.page-selector-image {
  top: 44%;
  left: calc(50% - (0.9 * 24px)); /* icon width = 24px, scaled 1.8x, half of the width left from the center */
  color: var(--teak);
  transform: scale(1.8);
}

.overlap-group-outer {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.overlap-group-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 1em;
}

.page-selector-underline {
  position: relative;
  top: 10%;
  width: 30%;
  left: 35%;
  height: 0.2em;
}
