.inner-content-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: min-content;
  width: 12e;
}

.desktop-bodycopy-18pt-calibri-regular {
  color: var(--secondary-text);
  font-weight: 400;
  margin-top: -1px;
}

.desktop-bodycopy-20pt-calibri-regular {
  color: var(--primary-text);
  font-weight: 400;
}

.inner-content-1.inner-content-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 30em;
}

.inner-content-1.inner-content-3 {
  width: min-content;
}

.inner-content-1.inner-content {
  margin-left: 7em;
  width: min-content;
}

.inner-content-1.inner-content-4 {
  width: 24em;
}

.inner-content-1.inner-content-7 {
  width: 13em;
}

.inner-content-1.inner-content-5,
.inner-content-1.inner-content-6,
.inner-content-1.inner-content-8,
.inner-content-1.inner-content-9 {
  width: 100%;
}

