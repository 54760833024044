:root {
  --icon-margin: 1em;
  --icon-size: 30px;
  --header-height: 4em;
}

.header {
  position: absolute;
  display: flex;
  align-items: flex-start;
  top: 0;
  left: 0;
  height: var(--header-height);
  width: 100%;
}

.header-full {
  align-items: center;
  background-color: var(--caramel);
  display: flex;
  height: 4em;
  left: 0;
  min-width: 100%;
  padding: 12px 20px;
  position: absolute;
  top: 0;
}

.logo-compass-group_zwart_rgb-1-1 {
  cursor: pointer;
  object-fit: cover;
  height: calc(0.75 * var(--header-height));
  width: calc(2 * var(--header-height));;
}

.vector-logout,
.vector-profile,
.user-name-function {
  position: absolute;
  cursor: pointer;
}

.vector-logout,
.vector-profile {
  height: var(--icon-size);
  width: var(--icon-size);
  margin-left: var(--icon-margin);
  margin-right: var(--icon-margin);
}

.vector-profile {
  left: calc(75% - var(--icon-size));
}

.vector-logout {
  left: calc(75% - 2*var(--icon-size) - var(--icon-margin));
}

.user-name-function {
  margin-top: 0.3em;
  left: calc(75% + 2*var(--icon-margin));

}
