.login-button {
  display: flex;
  align-items: center;
  background-color: var(--teak);
  border-radius: 0.7em;
  cursor: pointer;
  height: 3.5em;
  margin-top: 4em;
  min-width: 25vw;
}

.big-button {
  position: relative;
  left: calc(50%- 5vw);
  width: 10vw;
  text-align: center;
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-m);
  font-weight: 700;
  letter-spacing: 0;
}

.login-button-disabled {
  background-color: var(--teak-transparent);
}
