:root {
  --text-field-height: 1.6em;
}

.login-and-discover-the-portal {
  height: 2em;
  margin-top: 2em;
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xl);
  font-weight: 700;
  letter-spacing: 0;
}

.login-area {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  min-width: 30vw;
  height: 100%;
}

.login {
  letter-spacing: 0;
}

.please-enter-your-username-and-password {
  min-height: 3em;
  min-width: 35vw;
}

.username,
.password {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--grey);
  border-radius: 0.7em;
  height: 4.5em;
  margin-left: 0.1em;
  min-width: 35vw;
  padding: calc((var(--text-field-height)) / 4);
}

.password {
  margin-top: calc((var(--text-field-height)) / 2);
  padding-top: calc((var(--text-field-height)) / 2);
}

.username-input,
.password-input {
  align-self: center;
  color: var(--black);
  background-color: var(--grey);
  font-weight: 400;
}


input.username-input,
input.password-input {
  position: relative;
  margin-left: 0;
  border: 0;
  width: 70%;
}

.profile-filled-2,
.vector-30 {
  position: relative;
  height: calc((var(--text-field-height)) * 1.2);
  width: calc((var(--text-field-height)) * 1.2);
  margin-left: 20%;
  align-self: center;
}

.profile-filled-2 {
}

.vector-30 {
  margin-top: -2%;
}

.forgot-password {
  color: var(--primary-text);
  font-style: italic;
  font-weight: 400;
  margin-left: 269.0px;
  margin-top: 9px;
  min-height: 2em;
  min-width: 10vw;
}

.no-account-yet-register {
  color: var(--primary-text);
  margin-top: 0.25em;
  min-height: 2em;
  min-width: 18em;
}


.logo-compass-group_zwart_goud_rgb-1 {
  position: absolute;
  width: 30%;
  bottom: 10%;
  left: 35%;
  z-index: 1;
}
