.sla-parameters-1 {
  background-color: var(--white);
  height: 1024px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.overlap-group6-1 {
  height: 1024px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1441px;
}

.rectangle-42 {
  height: 490px;
  left: 155px;
  object-fit: cover;
  position: absolute;
  top: 528px;
  width: 581px;
}

.performance-objectives {
  height: 735px;
  left: 785px;
  position: absolute;
  top: 275px;
  width: 537px;
}

.overlap-group3-4 {
  border-radius: 10px;
  height: 735px;
  left: 0;
  position: absolute;
  top: 0;
  width: 537px;
}

.sustainbility {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 23px;
  min-height: 264px;
  position: absolute;
  top: 405px;
  width: 464px;
}

.benchmark-year {
  align-items: flex-start;
  display: flex;
  height: 46px;
  margin-top: 4px;
  min-width: 464px;
  position: relative;
}

.overlap-group-19 {
  border-radius: 8px;
  height: 35px;
  margin-left: 135px;
  margin-top: 1px;
  position: relative;
  width: 69px;
}

.number-6,
.percent,
.percent-1 {
  color: var(--teak);
  font-weight: 400;
  height: 34px;
  left: 11px;
  position: absolute;
  text-align: center;
  top: 1px;
  width: 47px;
}

.line-16 {
  height: 1px;
  margin-left: -3px;
  margin-top: 4px;
  width: 263px;
}

.food-waste {
  align-items: flex-end;
  display: flex;
  height: 46px;
  margin-top: 16px;
  min-width: 464px;
  position: relative;
}

.overlap-group-20 {
  border-radius: 8px;
  height: 35px;
  margin-left: 135px;
  position: relative;
  width: 69px;
}

.food {
  align-items: flex-end;
  display: flex;
  height: 46px;
  margin-top: 15px;
  min-width: 464px;
  position: relative;
}

.group-30 {
  border-radius: 8px;
  height: 35px;
  margin-bottom: 1px;
  margin-left: 135px;
  width: 69px;
}

.group-31 {
  border-radius: 8px;
  height: 35px;
  margin-left: 135px;
  width: 69px;
}

.scr {
  height: 72px;
  left: 23px;
  position: absolute;
  top: 295px;
  width: 464px;
}

.products {
  height: 73px;
  left: 22px;
  position: absolute;
  top: 181px;
  width: 465px;
}

.overlap-group-21 {
  border-radius: 8px;
  height: 35px;
  left: 396px;
  position: absolute;
  top: 29px;
  width: 69px;
}

.customer-satisfaction {
  height: 79px;
  left: 23px;
  position: absolute;
  top: 59px;
  width: 464px;
}

.overlap-group2-5 {
  height: 33px;
  left: 0;
  position: absolute;
  top: 0;
  width: 325px;
}

.vector-31 {
  cursor: pointer;
  height: 14px;
  left: 177px;
  position: absolute;
  top: 0;
  width: 14px;
}

.filters {
  align-items: flex-start;
  display: flex;
  height: 31px;
  left: 961px;
  min-width: 361px;
  position: absolute;
  top: 229px;
}

.company-abc-2 {
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  cursor: pointer;
  display: flex;
  height: 31px;
  min-width: 141px;
  padding: 0 10px;
}

.apartment_fill0_wght300_grad0_opsz48-1 {
  align-self: flex-end;
  height: 23px;
  width: 23px;
}

.company-abc-3 {
  color: var(--teak);
  font-weight: 400;
  margin-bottom: 1.0px;
  margin-left: 1px;
  min-height: 20px;
  min-width: 92px;
}

.location-z-3 {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  margin-left: 6px;
  min-width: 122px;
}

.overlap-group-22 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  height: 31px;
  min-width: 120px;
  padding: 5px 11.2px;
}

.vector-32 {
  align-self: center;
  height: 18px;
  margin-bottom: 1.0px;
  width: 13px;
}

.location-z-4 {
  color: var(--teak);
  font-weight: 400;
  margin-left: 11px;
  min-height: 20px;
}

.x2022-2 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  cursor: pointer;
  display: flex;
  height: 31px;
  margin-left: 4px;
  min-width: 88px;
  padding: 4px 8px;
}

.calendar_today_fill0 {
  height: 22px;
  width: 22px;
}

.number-7 {
  align-self: center;
  color: var(--teak);
  font-weight: 400;
  margin-bottom: 1.0px;
  margin-left: 6px;
  min-height: 20px;
  min-width: 33px;
}

.input-variables {
  height: 222px;
  left: 159px;
  position: absolute;
  top: 273px;
  width: 573px;
}

.overlap-group4-1 {
  border-radius: 10px;
  height: 222px;
  left: 0;
  position: absolute;
  top: 0;
  width: 573px;
}

.overlap-group2-6 {
  height: 35px;
  left: 177px;
  position: absolute;
  top: 74px;
  width: 363px;
}

.rectangle-28 {
  border-radius: 8px;
  height: 35px;
  left: 0;
  position: absolute;
  top: 0;
  width: 358px;
}

.fill-in-to-know-your-capture-rate {
  color: var(--teak);
  font-weight: 400;
  height: 34px;
  left: 10px;
  position: absolute;
  top: 0;
  width: 353px;
}

.logo-compass-group_zwart_rgb-1-4 {
  cursor: pointer;
  height: 40px;
  object-fit: cover;
  width: 96px;
}

.vector-33 {
  cursor: pointer;
  height: 27px;
  margin-left: 1060px;
  margin-top: 3.0px;
  width: 27px;
}

.vector-34 {
  cursor: pointer;
  height: 30px;
  margin-left: 15px;
  width: 30px;
}

.name-10 {
  align-self: flex-start;
  letter-spacing: 0;
  margin-left: 15px;
  min-height: 40px;
}

.span1-6 {
  color: var(--primary-text);
  font-weight: 400;
}

.sla-parameters-filled-1 {
  height: 32px;
  left: 17px;
  position: absolute;
  top: 301px;
  width: 32px;
}

