.welcome-to-compass-group {
  text-align: center;
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xl);
  font-weight: 700;
  letter-spacing: 0;
  min-height: 4em;
  margin-top: 2em;
}
