.view-all-2 {
  color: var(--husk);
  font-weight: 400;
}

.view-all-3 {
  position: relative;
  display: flex;
  align-items: flex-end;
  top: 0;
  right: -80%;
  height: 3em;
  margin-right: 10%;
  width: 100%;
  min-width: 7em;
}

.view-all-3.view-all-4 {
}

.arrow-forward-6 {
  align-self: center;
  height: 1em;
  margin-bottom: 0.25em;
  margin-left: 0.85em;
  margin-top: 1.3em;
  width: 0.85em;
}

