.home-landing-page {
  background-color: var(--white);
  overflow: hidden;
  position: relative;
}

.overlap-group4 {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.left-group {
  align-items: center;
  background-color: var(--husk);
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 95vh;
  padding: 2em 0;
  position: absolute;
  top: 4em;
  width: 35vw;
  min-width: 12vw;
}

.left-header-1,
.left-header-2 {
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-weight: 700;
  letter-spacing: 0;
  min-height: 1.5em;
  width: 80%;
  text-align: center;
}

.left-header-1 {
  font-size: var(--font-size-xl);
  margin-top: 1.5vh;
}

.left-header-2 {
  font-size: var(--font-size-m);
  font-style: italic;
}

.ellipse-2 {
  width: 60%;
  margin-top: 4em;
  object-fit: cover;
}

.welcome-message {
  text-align: center;
  color: var(--white);
  font-size: var(--font-size-m);
  font-weight: 400;
  margin-top: 2.5em;
  width: 80%;
  min-height: 6em;
}

.mail-and-mobile {
  height: 3em;
  margin-top: 2.5em;
  width: 7vw;
}

.logo-compass-group_zwart_rgb-1 {
  height: 40px;
  object-fit: cover;
  width: 96px;
}

.name {
  align-self: flex-start;
  letter-spacing: 0;
  margin-left: 15px;
  min-height: 40px;
}

.span1-1 {
  color: var(--primary-text);
  font-weight: 400;
}

/* Positioning of tiles */
.selector-pos {
  position: absolute;
  display: flex;
  align-items: flex-start;
  height: 25vh;
  min-width: 24vw;
}
.selector-pos.dense {
  transform: scaleY(0.9);
}

.selector-pos-row-1 {
  top: 32vh;
}
.selector-pos-row-2 {
  top: 66vh;
}

.selector-pos-row-1.dense {
  top: 22vh;
}
.selector-pos-row-2.dense {
  top: 48vh;
}
.selector-pos-row-3.dense {
  top: 74vh;
}

.selector-pos-col-1 {
  left: 39vw;
}
.selector-pos-col-2 {
  left: 64vw;
}
