@import url("reset-2.0.min.css");
@import url("font-pt-serif-400-700.css");
@import url("font-merriweather-sans-400.css");

/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
/*@import url("https://px.animaapp.com/62bc93f04d1a76aff1af8123.62bc93f107b3749810e3e7c2.TSGmzcK.rcp.png");*/

@font-face {
  font-family: "Calibri-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Calibri.ttf") format("truetype");
}
@font-face {
  font-family: "Calibri-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/calibri-bold.ttf") format("truetype");
}
@font-face {
  font-family: "Calibri-Italic";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/Calibri Italic.ttf") format("truetype");
}

.screen {
  width: 100vw;
  height: 100vh;
}

.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.overlay {
  display: none;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.overlay.animate-appear {
  animation: reveal 0.3s ease-in-out 1 normal forwards;
  display: block;
  opacity: 0;
}

.overlay.animate-disappear {
  animation: reveal 0.3s ease-in-out 1 reverse forwards;
  display: block;
  opacity: 1;
  pointer-events: none;
}

.overlay.animate-disappear * {
  pointer-events: none;
}

.align-self-flex-start {
  align-self: flex-start;
}

.align-self-flex-end {
  align-self: flex-end;
}

.align-self-flex-center {
  align-self: flex-center;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

* {
  box-sizing: border-box;
}
