.last-year-filter {
  align-items: flex-start;
  display: flex;
  width: 1440px;
}

.overlay .last-year-filter {
  background-color: #00000000;
}

.last-year-filter-1 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 1024px;
  left: calc(50vw - 720.0px);
  padding: 229px 101px;
  position: relative;
  top: calc(50vh - 512.0px);
  width: 1440px;
}

.overlap-group-15 {
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  height: 31px;
  margin-right: 64px;
  min-width: 139px;
  padding: 5px 13.2px;
}

.vector-24 {
  height: 18px;
  margin-bottom: 1.11px;
  width: 16px;
}

.last-year {
  align-self: flex-end;
  color: var(--teak);
  font-weight: 400;
  margin-left: 9px;
  min-height: 20px;
  min-width: 58px;
}

.filter-options-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  min-height: 183px;
  padding: 14px 15px;
  width: 203px;
}

.flex-row-4 {
  align-items: flex-start;
  display: flex;
  min-width: 79px;
}

.vector-23 {
  height: 20px;
  width: 20px;
}

.last {
  color: var(--teak);
  font-weight: 400;
  margin-left: 6px;
  min-height: 20px;
}

.flex-row-5 {
  align-items: flex-start;
  display: flex;
  height: 21px;
  margin-top: 6px;
  min-width: 105px;
}

.vector-25 {
  align-self: flex-end;
  height: 20px;
  width: 20px;
}

.flex-row-6 {
  align-items: flex-start;
  display: flex;
  margin-top: 5px;
  min-width: 105px;
}

.flex-row-7 {
  align-items: flex-start;
  display: flex;
  height: 21px;
  margin-top: 5px;
  min-width: 105px;
}

.last-quarter {
  align-self: flex-end;
  color: var(--teak);
  font-weight: 400;
  margin-left: 6px;
  min-height: 20px;
  min-width: 79px;
}

.flex-row-8 {
  align-items: flex-start;
  display: flex;
  margin-top: 4px;
  min-width: 84px;
}

.line-18-1 {
  height: 1px;
  margin-top: 8px;
  width: 146px;
}

.flex-row-9 {
  align-items: center;
  display: flex;
  height: 20px;
  margin-left: 3.25px;
  margin-top: 4px;
  min-width: 102px;
}

.vector-26 {
  cursor: pointer;
  height: 14px;
  width: 14px;
}

.custom-date {
  color: var(--teak);
  cursor: pointer;
  font-weight: 400;
  margin-left: 6px;
  min-height: 20px;
  min-width: 83px;
}

#overlay-last-year-filter {
  background-color: transparent;
}

