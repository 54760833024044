.users-overview-page {
  background-color: var(--white);
  overflow: hidden;
  position: relative;
}

.overlap-group-users-overview {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.users-table-container {
  position: absolute;
  width: 80vw;
  min-height: 8vh;
  max-height: 65vh;
  left: 10vw;
  top: 18vh;
}

.users-table,
.users-table-placeholder {
  position: relative;
  margin-bottom: 2em;
}

/* Style for nested <div> inside a table cell */
.users-table td.MuiTableCell-root>div.nested-table-cell {
  padding: 0;
  border: none;
}

.users-table-placeholder {
  top: 10vh;
  height: 10vh;
}

.reload-users-button {
  minimum-width: 10em;
  color: black;
  background-color: var(--caramel);
}

.registration-link-modal,
.message-modal {
  position: absolute;
  font-family: var(--font-family-calibri-regular);
  font-weight: 400;
  color: var(--error-foreground);
  background-color: var(--caramel);
  padding: 1em;
  text-align: left;
  border-style: solid;
  border-color: var(--dask);
  border-width: 0.2em;
  overflow-wrap: break-word;
}

.registration-link-modal:hover,
.message-modal:hover {
  cursor: pointer;
}

.registration-link-modal {
  left: 20vw;
  top: 40vh;
  width: 60vw;
  z-index: 1;
  font-size: var(--font-size-xs);
}

.registration-link-modal a.subject-header {
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-s);
  font-weight: 700;
}

.registration-link-modal .registration-link-item {
  margin-top: 0.25em;
  margin-bottom: 0.5em;
}

.registration-link-modal .registration-link-modal-footer {
  text-align: right;
}

.message-modal {
  left: 40vw;
  top: 45vh;
  width: 20vw;
  z-index: 2;
  font-size: var(--font-size-m);
}
