:root {
  --left-navigation-bar-width: 4em;
  --left-navigation-bar-height: calc(100vh - 4em);
  --left-navigation-bar-y: 4em;

}

.left-navigation-bar-container {
  position: absolute;
  top: var(--left-navigation-bar-y);
  left: 0;
  height: var(--left-navigation-bar-height);
  width: var(--left-navigation-bar-width);
}

.left-navigationbar {
  align-items: center;
  background-color: var(--husk);
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: var(--left-navigation-bar-height);;
  width: 100%;
  padding: 1em 0 1em 0;
  top: 0;
}

.left-navigationbar .left-navigationbar-icon {
  cursor: pointer;
}

/* Icon positioning */
.left-navigationbar-icon {
  color: var(--white);
  height: 5em;
  width: 5em;
  margin-left: 0.3em;
  margin-right: 0.3em;
  transform: scale(1.15);
}

.left-navigationbar .position-top {
  position: relative;
  margin-top: 0.25em;
  margin-bottom: 0.5em;
}

.left-navigationbar img.position-bottom {
  position: absolute;
}

img.position-bottom:nth-last-child(1) { --nth-last-child: 1 }
img.position-bottom:nth-last-child(2) { --nth-last-child: 2 }
img.position-bottom:nth-last-child(3) { --nth-last-child: 3 }

.left-navigationbar img.position-bottom:nth-last-child(-n+3) {
  bottom: calc(10px + var(--nth-last-child) * var(--left-navigation-bar-width));
}

/* Icon colors */
.transform-svg-colors {
  -webkit-filter: invert(25%); /* safari 6.0 - 9.0 */
  filter: invert(25%);
}

/* Icon sizes */
.left-navigationbar-icon {
  width: 32px;
}
