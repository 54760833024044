.frame-1-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: min-content;
  width: 18%;
}

.desktop-bodycopy-20pt-calibri-regular-2 {
  color: var(--primary-text);
  font-weight: 400;
  margin-top: -1px;
  width: 16%;
}

.desktop-bodycopy-18pt-calibri-regular-1 {
  color: var(--secondary-text);
  font-weight: 400;
  width: 18%;
}

.frame-1-4.frame-1-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: min-content;
  left: 0;
  position: absolute;
  top: 2.2em;
  width: 16%;
}

.frame-1-4.frame-1-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: min-content;
  left: 1px;
  position: absolute;
  top: 2.3em;
  width: 16%;
}

.frame-1-4.frame-1-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: min-content;
  left: 0;
  position: absolute;
  top: 3em;
  width: 16%;
}
