.innter-content-3-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 25px;
  width: 325px;
}

.desktop-bodycopy-20pt-calibri-regular-1 {
  color: var(--primary-text);
  font-weight: 400;
  margin-top: -1px;
  min-height: 24px;
}

.line-3 {
  height: 1px;
  margin-top: 2px;
  width: 100px;
}

.innter-content-3-1.innter-content-3 {
  left: 0;
  position: absolute;
  top: 0;
}

.innter-content-3-1.innter-content-3-2 {
  left: 0;
  position: absolute;
  top: 8px;
}

