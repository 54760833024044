:root {
  --text-field-height: 1.6em;
}

.login-screen {
  display: flex;
  align-items: flex-start;
  background-color: var(--white);
}

.proposition-summary {
  align-items: flex-start;
  justify-content: flex-end;
  display: flex;
}

.overlap-group-login-left,
.overlap-group-login-right {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50vw;
  height: 100vh;
  padding-left: 4em;
  padding-right: 4em;
}

.overlap-group-login-left {
   background-image: url(/static/img/rectangle-7@1x.svg);
}

.overlap-group-login-right {
  background-color: var(--background-color);
}

.login-and-discover-the-portal {
  height: 2em;
  margin-top: 2em;
  color: var(--white);
  font-family: var(--font-family-pt_serif);
  font-size: var(--font-size-xl);
  font-weight: 700;
  letter-spacing: 0;
}

.login-area {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  min-width: 30vw;
}

.login {
  letter-spacing: 0;
  margin-top: 2em;
  margin-bottom: 2em;
  left: -8em;
}

.please-enter-your-username-and-password {
  margin-top: 2em;
  margin-bottom: 1em;
}

.username,
.password {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--grey);
  border-radius: 0.7em;
  height: 4.5em;
  margin-left: 0.1em;
  min-width: 35vw;
  padding: calc((var(--text-field-height)) / 4);
}

.password {
  margin-top: calc((var(--text-field-height)) / 2);
  padding-top: calc((var(--text-field-height)) / 2);
}

.username-failed,
.password-failed,
.username-failed input,
.password-failed input {
  color: var(--teak);
}

.username-input,
.password-input {
  align-self: center;
  color: var(--black);
  background-color: var(--grey);
  font-weight: 400;
}

input.username-input,
input.password-input {
  position: relative;
  margin-left: 0;
  border: 0;
  width: 70%;
}

.profile-filled-2,
.vector-30 {
  position: relative;
  height: calc((var(--text-field-height)) * 1.2);
  width: calc((var(--text-field-height)) * 1.2);
  margin-left: 20%;
  align-self: center;
}

.vector-30 {
  margin-top: -2%;
}

.forgot-password {
  margin-top: 1em;
  min-height: 2em;
  min-width: 10vw;
  margin-left: 0em;
  width: 100%;
  color: var(--primary-text);
  font-style: italic;
  font-weight: 400;
  text-align: right;
  padding-right: 10em;
  margin-top: 0em;
}

.no-account-yet-register {
  color: var(--primary-text);
  margin-top: 0.25em;
  min-height: 2em;
  min-width: 18em;
}

.span0-4 {
  font-weight: 400;
}

.span1-5 {
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-m);
  font-weight: 700;
  letter-spacing: 0;
}

.logo-compass-group-login-screen {
  width: 40%;
  margin-top: 2em;
  margin-bottom: auto;
  z-index: 1;
}

.client-dashboard-modal {
  position: absolute;
  left: 10vw;
  font-family: var(--font-family-calibri-regular);
  font-weight: 400;
  color: var(--error-foreground);
  background-color: var(--caramel);
  padding: 1em;
  text-align: left;
  border-style: solid;
  border-color: var(--dask);
  border-width: 0.2em;
  overflow-wrap: break-word;
}

.client-dashboard-modal:hover {
  cursor: pointer;
}

.password-change-confirmation-modal {
  background-color: var(--caramel);
  left: 20vw;
  top: 40vh;
  width: 60vw;
  z-index: 1;
  font-size: var(--font-size-xs);
}

.password-change-confirmation-modal a.subject-header {
  font-family: var(--font-family-calibri-bold);
  font-size: var(--font-size-s);
  font-weight: 700;
}

.password-change-confirmation-modal .modal-content-item {
  margin-top: 0.25em;
  margin-bottom: 0.5em;
}

.password-change-confirmation-modal .registration-link-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: center;
}

.password-change-confirmation-modal .registration-link-modal-footer .modal-confirm,
.password-change-confirmation-modal .registration-link-modal-footer .modal-cancel {
  width: 25%;
  font-size: var(--font-size-m);
  border-radius: 4px;
  padding-top: 0.3em;
  padding-bottom: 0.1em;
  margin-left: 1em;
}

.password-change-confirmation-modal .modal-confirm {
  color: var(--confirm-foreground);
  background-color: var(--confirm-background);
  border: 1px solid var(--confirm-foreground);
}

.password-change-confirmation-modal .modal-cancel {
  color: var(--cancel-foreground);
  background-color: var(--cancel-background);
  border: 1px solid var(--confirm-foreground);
}

div.modal-confirm:disabled,
div.modal-cancel:disabled {
  background-color: var(--grey);
}
