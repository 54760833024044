.inner-content-2-1 {
  align-items: flex-start;
  display: flex;
  height: 46px;
  left: 24px;
  min-width: 230px;
  position: absolute;
  top: 74px;
}

